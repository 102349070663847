import gql from 'graphql-tag';

import {
 CONTENT_REVIEW_COMMENT_FRAGMENT, CONTENT_REVIEW_EVENT_FRAGMENT, CONTENT_REVIEW_FRAGMENT, MEMBER_FRAGMENT,
} from './fragments';

export const GET_GROUP_CONTENT_REVIEWS_QUERY = gql`
  query GetContentReviewsQuery($programId: Int, $memberId: Int, $statuses: [ContentReviewStatus!], $rawStates: [RawContentReviewState!]) {
    reviews: getAllGroupContentReviews(programId: $programId, memberId: $memberId, statuses: $statuses, rawStates: $rawStates) {
      ...ContentReviewFragment
      isGCR
      member {
        ...MemberFragment
      }
      comments {
        ...ContentReviewCommentFragment
      }
      events {
        ...ContentReviewEventFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
  ${CONTENT_REVIEW_EVENT_FRAGMENT}
`;

export const GET_CONTENT_REVIEW_QUERY = gql`
  query GetContentReviewQuery($id: Int!) {
    review: getContentReviewById(id: $id) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
      comments {
        ...ContentReviewCommentFragment
      }
      events {
        ...ContentReviewEventFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
  ${CONTENT_REVIEW_EVENT_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const CREATE_CONTENT_REVIEW_MUTATION = gql`
  mutation CreateContentReviewMutation($params: CreateContentReviewParams!) {
    review: createContentReview(params: $params) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;
export const PROMOTE_TO_GCR = gql`
  mutation PromoteToGCR($params: CreateContentReviewParams!) {
    review: promoteToGCR(params: $params) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBER_IDS_BY_INTERNAL_STATUS = gql`
  query GetMemberIdsByInternalStatus($programId: Float!, $statuses: [InternalContentReviewStatus!]!) {
    memberIds: getMembersByContentReviewStatus(programId: $programId, statuses: $statuses)
  }
`;

export const APPROVE_CONTENT_REVIEW_MUTATION = gql`
  mutation ApproveContentReviewMutation($id: Int!, $comment: String) {
    review: approveContentReview(id: $id, comment: $comment) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
      comments {
        ...ContentReviewCommentFragment
      }
      events {
        ...ContentReviewEventFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
  ${CONTENT_REVIEW_EVENT_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const REJECT_CONTENT_REVIEW_MUTATION = gql`
  mutation RejectContentReviewMutation($id: Int!, $comment: String!, $checkOffs: [Boolean!]) {
    review: rejectContentReview(id: $id, comment: $comment, checkOffs: $checkOffs) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
      comments {
        ...ContentReviewCommentFragment
      }
      events {
        ...ContentReviewEventFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
  ${CONTENT_REVIEW_EVENT_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const GUEST_APPROVE_CONTENT_REVIEW_MUTATION = gql`
  mutation GuestApproveContentReviewMutation($id: Int!, $comment: String, $guidelines: [String!]) {
    review: guestApproveContentReview(id: $id, comment: $comment, guidelines: $guidelines) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
      comments {
        ...ContentReviewCommentFragment
      }
      events {
        ...ContentReviewEventFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
  ${CONTENT_REVIEW_EVENT_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const GUEST_REJECT_CONTENT_REVIEW_MUTATION = gql`
  mutation GuestRejectContentReviewMutation($id: Int!, $comment: String!, $guidelines: [String!]) {
    review: guestRejectContentReview(id: $id, comment: $comment, guidelines: $guidelines) {
      ...ContentReviewFragment
      member {
        ...MemberFragment
      }
      comments {
        ...ContentReviewCommentFragment
      }
      events {
        ...ContentReviewEventFragment
      }
    }
  }
  ${CONTENT_REVIEW_FRAGMENT}
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
  ${CONTENT_REVIEW_EVENT_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const CREATE_CONTENT_REVIEW_COMMENT_MUTATION = gql`
  mutation CreateContentReviewCommentMutation($params: CreateContentReviewCommentParams!) {
    comment: createContentReviewComment(params: $params) {
      ...ContentReviewCommentFragment
    }
  }
  ${CONTENT_REVIEW_COMMENT_FRAGMENT}
`;

/**
 * backend server content reviews
 */
export const GET_RAW_CONTENT_REVIEWS_QUERY = gql`
  query GetRawContentReviewsQuery($programId: Int!, $memberId: Int!) {
    reviews: getRawContentReviews(programId: $programId, memberId: $memberId)
  }
`;

export const REJECT_RAW_CONTENT_REVIEW_MUTATION = gql`
  mutation RejectRawContentReviewMutation($projectId: Float!, $contentReviewId: Float!, $comment: String!, $checkOffs: [Boolean!]) {
    review: rejectRawContentReview(projectId: $projectId, contentReviewId: $contentReviewId, comment: $comment, checkOffs: $checkOffs)
  }
`;
