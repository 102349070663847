import * as React from 'react';
import { Layout, Modal } from 'antd';
import Helmet from 'react-helmet';

import { useAuth } from '@frontend/context/authContext';
import {
  isEmpty, includes, map, startCase, toLower, first,
} from 'lodash';
import CustomMenu from '@frontend/app/components/CustomMenu/CustomMenu';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { Notice } from '@components';
import {
  Route,
  useParams,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import {
  CONTRACT_APP_ID,
  PAYMENT_APP_ID,
  GMAIL_APP_ID,
  GDRIVE_APP_ID,
  SHOPIFY_APP_ID,
  TERMS_APP_ID,
  SOCIAL_POST_APP_ID,
  OUTLOOK_APP_ID,
  WOO_COMMERCE_APP_ID,
  ALLOY_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { TermsGuidelinesSettings } from '@frontend/applications/TermsApp/pages/TermsGuidelinesSettings';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import ShopifySettingsPage from '@frontend/applications/ShopifyApp/pages/ShopifySettingsPage';
import WooCommerceSettingsPage from '@frontend/applications/WooCommerceApp/pages/WooSettingsPage';
import {
  useAreApplicationsInstalled,
  useClientFeatureEnabled,
  useGetAlloyMetadata,
  useGetInstalledApplicationIds,
} from '@frontend/app/hooks';
import ContentGuidelines from '@frontend/applications/TermsApp/containers/ContentGuidelines/ContentGuidelines';
import { InstagramMenuAlert } from '@frontend/app/components';
import { InstagramMeta } from '@frontend/app/components/InstagramMeta/MetaItems/pages/InstagramMeta';
import styles from './SettingsPage.scss';
import {
  AccountSettings,
  NotificationSettings,
  MemberRestrictionSettings,
  TeammateSettings,
  AnalyticsSettings,
} from '../Settings/SettingsContent';
import ContractSignersPage from '../../../applications/ContractApp/pages/ContractSignersPage';
import { PaymentSources } from '../../../applications/PaymentsApp/components/PaymentSources/PaymentSources';
import { AccountsPage } from '../EmailSettings/AccountsPage';
import { AlloyIntegrations } from '../Settings/Alloy';
import { PlanDetailSettings } from '../Settings/SettingsContent/PlanDetailSettings';
import { MessageTemplatesPage } from '../EmailSettings/MessageTemplatesPage/MessageTemplatesPage';
import { TiktokSettingsPage } from '../TiktokSettings/pages/TiktokSettingsPage';
import { pfaV2SettingsConfig } from './settingsConfig';
import { ProductFulfillmentRouter } from '../Settings/ProductFulfillment/ProductFulfillmentRouter';
import GdriveSettingsPage from '../../../applications/GoogleDriveApp/pages/GdriveSettingsPage';
import BudgetReportingContainer from '../BudgetReporting/BudgetReportingContainer';

const { useMemo, useEffect, useCallback } = React;
const { Content, Sider } = Layout;

interface IMatchParams {
  applicationId: string;
}

const applicationDictionary: { [applicationId: string]: string } = {
  [PAYMENT_APP_ID]: 'Payment',
  [CONTRACT_APP_ID]: 'Contracts',
  [TERMS_APP_ID]: 'Terms',
  [GMAIL_APP_ID]: 'Gmail',
  [GDRIVE_APP_ID]: 'Google Drive',
  [OUTLOOK_APP_ID]: 'Outlook',
  [SOCIAL_POST_APP_ID]: 'Social Listening',
  [SHOPIFY_APP_ID]: 'Shopify',
  [WOO_COMMERCE_APP_ID]: 'WooCommerce',
};

const SettingsPage: React.FunctionComponent<RouteComponentProps> = () => {
  const { applicationId } = useParams<IMatchParams>();
  const { clientInfo, user } = useAuth();
  const installedApps = useGetInstalledApplicationIds();
  const history = useHistory();
  const { metadata } = useGetAlloyMetadata();
  const productCostEnabled = useClientFeatureEnabled(ClientFeature.PRODUCT_COST);
  const alloyEnabled = useClientFeatureEnabled(ClientFeature.ALLOY_INTEGRATIONS);
  const upsellGates = useClientFeatureEnabled(ClientFeature.UPSELL_GATES);
  const pfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const contentGuidelinesEnabled = useClientFeatureEnabled(ClientFeature.CONTENT_GUIDELINES);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isBudgetDashboardEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_DASHBOARD);
  const alloyApp = useAreApplicationsInstalled([ALLOY_APP_ID]);
  const contractSignersPageClasses = [styles.SettingsLayout, styles.setting_detail];
  const emailTemplatesPageClasses = [styles.MessageTemplatesPage, styles.header];
  const isBrandAdmin = includes(user['https://aspirex.api.com/roles'], 'manager:admin');
  const showErrorModal = useCallback((applicationId: string) => {
    const appName = [GMAIL_APP_ID, OUTLOOK_APP_ID].includes(applicationId)
      && !installedApps[GMAIL_APP_ID] && !installedApps[OUTLOOK_APP_ID] ? 'Gmail or Outlook' : applicationDictionary[applicationId];
    Modal.error({
      maskClosable: true,
      content: (
        <>
          Looks like we need to install the
          {' '}
          {appName}
          {' '}
          application in your account. Please contact support at
          {' '}
          <a href="mailto:help@aspireiq.com">help@aspireiq.com</a>
          .
        </>
      ),
      onOk: () => history.push('/home'),
    });
  }, [installedApps, history]);

  useEffect(() => {
    if ([
      GMAIL_APP_ID,
      GDRIVE_APP_ID,
      OUTLOOK_APP_ID,
      PAYMENT_APP_ID,
      CONTRACT_APP_ID,
      TERMS_APP_ID,
      SHOPIFY_APP_ID,
      WOO_COMMERCE_APP_ID,
      SOCIAL_POST_APP_ID,
    ].includes(applicationId)
      && !isEmpty(installedApps)
      && !installedApps[applicationId]) {
      showErrorModal(applicationId);
    }
  }, [applicationId, installedApps, showErrorModal]);

  const alloyInstalled = useMemo(() => first(alloyApp?.isInstalled), [alloyApp]);

  const alloyIntegrations = useMemo(() => {
    if (!alloyEnabled || !alloyInstalled) {
      return [];
    }

    return map(metadata?.availableIntegrations, (integration) => ({
      id: toLower(integration),
      text: startCase(toLower(integration)),
      route: `/settings/alloy/${toLower(integration)}`,
    }));
  }, [alloyEnabled, alloyInstalled, metadata]);

  const items = [
    {
      id: 'general',
      text: 'General',
      subItems: [
        {
          id: 'account',
          text: 'Account',
          route: '/settings/account',
        },
        {
          id: 'notifications',
          text: 'Notifications',
          route: '/settings/notifications',
        },
        ...(productCostEnabled
          ? [
              {
                id: 'analytics',
                text: 'Analytics',
                route: '/settings/analytics',
              },
            ]
          : []),
      ],
    },
    {
      id: 'workspace',
      text: 'Workspace',
      subItems: [
        installedApps[PAYMENT_APP_ID] && {
          id: PAYMENT_APP_ID,
          text: 'Payments',
          route: `/settings/${PAYMENT_APP_ID}`,
        },
        installedApps[CONTRACT_APP_ID] && {
          id: CONTRACT_APP_ID,
          text: 'Contracts',
          route: `/settings/${CONTRACT_APP_ID}`,
        },
        installedApps[TERMS_APP_ID] && {
          id: TERMS_APP_ID,
          text: 'Content Guidelines',
          route: `/settings/${TERMS_APP_ID}`,
        },
        {
          id: 'messageTemplates',
          text: 'Message Templates',
          route: '/settings/messageTemplates',
        },
        {
          id: 'teammates',
          text: 'Team Members',
          route: '/settings/teammates',
        },
        ...(isBudgetAllocationEnabled && isBudgetDashboardEnabled
          ? [
              {
                id: 'budgets',
                text: 'Budgets',
                route: '/settings/budget/dashboard',
              },
            ]
          : []),
      ].filter(Boolean),
    },
    ...pfaV2SettingsConfig(pfaV2Enabled && installedApps[SHOPIFY_APP_ID]),
    {
      id: 'integrations',
      text: 'Integrations',
      subItems: [
        installedApps[GMAIL_APP_ID] && {
          id: GMAIL_APP_ID,
          text: 'Gmail',
          route: `/settings/${GMAIL_APP_ID}`,
        },
        installedApps[GDRIVE_APP_ID] && {
          id: GDRIVE_APP_ID,
          text: 'Google Drive',
          route: `/settings/${GDRIVE_APP_ID}`,
        },
        installedApps[OUTLOOK_APP_ID] && {
          id: OUTLOOK_APP_ID,
          text: 'Outlook',
          route: `/settings/${OUTLOOK_APP_ID}`,
        },
        installedApps[SOCIAL_POST_APP_ID] && {
          id: SOCIAL_POST_APP_ID,
          text: <InstagramMenuAlert>Instagram (Meta)</InstagramMenuAlert>,
          route: `/settings/${SOCIAL_POST_APP_ID}`,
        },
        SOCIAL_POST_APP_ID && {
          id: 'tiktok',
          text: 'TikTok',
          route: '/settings/tiktok',
        },
        installedApps[SHOPIFY_APP_ID] && {
          id: SHOPIFY_APP_ID,
          text: 'Shopify',
          route: `/settings/${SHOPIFY_APP_ID}`,
        },
        installedApps[WOO_COMMERCE_APP_ID] && {
          id: WOO_COMMERCE_APP_ID,
          text: 'WooCommerce',
          route: `/settings/${WOO_COMMERCE_APP_ID}`,
        },
        ...alloyIntegrations,
      ].filter(Boolean),
    },
    {
      id: 'organization',
      text: 'Organization',
      subItems: [
        {
          id: 'conversationPrivacy',
          text: 'Conversation Privacy',
          route: '/settings/conversationPrivacy',
        },
      ],
    },
  ];

  if (upsellGates) {
    items[0].subItems.push({
      id: 'planDetails',
      text: 'Plan Details',
      route: '/settings/planDetails',
    });
  }

  const renderActiveApp = () => {
    switch (applicationId) {
      case PAYMENT_APP_ID:
        return <PaymentSources title="Payment Settings" />;
      case CONTRACT_APP_ID:
        return <ContractSignersPage classes={contractSignersPageClasses} showNav={false} title="Contracts" />;
      case TERMS_APP_ID:
        return contentGuidelinesEnabled
          ? <ContentGuidelines />
          : <TermsGuidelinesSettings className={styles.TermsGuidelinesSettings} title="Content Guidelines" />;
      case GMAIL_APP_ID:
        return <AccountsPage type={ResourceType.GMAIL} title="Gmail Settings" />;
      case GDRIVE_APP_ID:
        return <GdriveSettingsPage />;
      case OUTLOOK_APP_ID:
        return <AccountsPage type={ResourceType.OUTLOOK} title="Outlook Settings" />;
      case SOCIAL_POST_APP_ID:
        return <InstagramMeta />;
      case SHOPIFY_APP_ID:
        return <ShopifySettingsPage className={styles.ShopifyAccountList} title="Shopify Settings" />;
      case WOO_COMMERCE_APP_ID:
        return <WooCommerceSettingsPage title="WooCommerce Settings" />;
    }
  };

  return (
    <Layout className={styles.SettingsPage}>
      <Helmet title="Settings" />
      <Sider theme="light" width={210}>
        <CustomMenu items={items} selectedKeys={[applicationId]} />
      </Sider>
      <Layout>
        <Content>
          <Switch>
            <Route path="/settings/account" component={AccountSettings} />
            <Route path="/settings/notifications" component={NotificationSettings} />
            {productCostEnabled ? <Route path="/settings/analytics" component={AnalyticsSettings} /> : null}
            {
              upsellGates && (
                <Route path="/settings/planDetails" component={PlanDetailSettings} />
              )
            }
            <Route
              path="/settings/tiktok"
              render={() => (
                <ApplicationProvider
                  backendServerApiEndpoint={backendServerApiEndpoint()}
                  analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
                  backendServerWebEndpoint={backendServerWebEndpoint()}
                  clientId={clientInfo.id}
                  clientName={clientInfo.name}
                >
                  <TiktokSettingsPage />
                </ApplicationProvider>
              )}
            />
            <Route path="/settings/teammates" component={TeammateSettings} />
            <Route path="/settings/budget" component={BudgetReportingContainer} />
            <Route path="/settings/messageTemplates" render={() => <MessageTemplatesPage classes={emailTemplatesPageClasses} showHeader={false} />} />
            <Route
              path="/settings/conversationPrivacy"
              render={() => (
                <>
                  {isBrandAdmin && <MemberRestrictionSettings />}
                  {!isBrandAdmin
                    && (
                      <Notice type="error" showDivider>
                        You don't have access to conversation privacy settings.
                      </Notice>
                    )}
                </>
              )}
            />
            <Route
              path="/settings/alloy/:integration"
              component={AlloyIntegrations}
            />
            {installedApps[applicationId] && (
              <Route
                path="/settings/:applicationId"
                render={() => (
                  <>
                    <ApplicationProvider
                      backendServerApiEndpoint={backendServerApiEndpoint()}
                      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
                      backendServerWebEndpoint={backendServerWebEndpoint()}
                      clientId={clientInfo.id}
                      clientName={clientInfo.name}
                    >
                      {renderActiveApp()}
                    </ApplicationProvider>
                  </>
                )}
              />
            )}
            <ProductFulfillmentRouter />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(SettingsPage);
