import React, { useState } from 'react';
import { WidgetContainer } from '@frontend/app/composite-components';
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';
import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { cn } from '@/shadcn/lib/utils';

import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from '@/shadcn/components/ui/chart';

const chartData = {
    Month: [
        { month: 'Jan', spent: 18600 },
        { month: 'Feb', spent: 30555 },
        { month: 'Mar', spent: 23700 },
        { month: 'Apr', spent: 7300 },
        { month: 'May', spent: 15000 },
        { month: 'Jun', spent: 5500 },
        { month: 'Jul', spent: 84888 },
        { month: 'Aug', spent: 5555 },
        { month: 'Sep', spent: 0 },
        { month: 'Oct', spent: 0 },
        { month: 'Nov', spent: 0 },
        { month: 'Dec', spent: 0 },
    ],
    Week: [
        { week: 'W1', spent: 5000 },
        { week: 'W2', spent: 7000 },
        { week: 'W3', spent: 8500 },
        { week: 'W4', spent: 9200 },
        { week: 'W5', spent: 7500 },
        { week: 'W6', spent: 6000 },
        { week: 'W7', spent: 9800 },
        { week: 'W8', spent: 11000 },
        { week: 'W9', spent: 11500 },
        { week: 'W10', spent: 12000 },
        { week: 'W11', spent: 9500 },
        { week: 'W12', spent: 10500 },
    ],
    Quarter: [
        { quarter: 'Q1', spent: 72855 },
        { quarter: 'Q2', spent: 27800 },
        { quarter: 'Q3', spent: 84888 },
        { quarter: 'Q4', spent: 0 },
    ],
};

const chartConfig = {
    spent: {
        label: 'Spent',
        color: '#00A5A6',
    },
    stroke: {
        color: '#b1eaea',
    },
} satisfies ChartConfig;

interface SpendActivityWidgetProps {
    className?: string;
}

export default function SpendActivityWidget({ className }: SpendActivityWidgetProps) {
    const [timeline, setTimeline] = useState<string>('Month');

    const widgetActions = (
      <div className="flex items-center gap-2">
        <ToggleGroup
          className="border border-gray-300 rounded-lg overflow-hidden inline-flex"
          type="single"
          value={timeline}
          onValueChange={(value) => setTimeline(value || 'Month')}
        >
          <ToggleGroupItem value="Week">W</ToggleGroupItem>
          <ToggleGroupItem value="Month">M</ToggleGroupItem>
          <ToggleGroupItem value="Quarter">Q</ToggleGroupItem>
        </ToggleGroup>
      </div>
      );

    const dataKey = timeline === 'Month' ? 'month' : timeline === 'Week' ? 'week' : 'quarter';

    return (
      <WidgetContainer
        widgetTitle="Spend Activity"
        className={cn(className, '')}
        bgColor="bg-white"
        textColor="text-gray-700"
        widgetActions={widgetActions}
      >
        <ChartContainer config={chartConfig} className="h-[420px] w-full">
          <AreaChart data={chartData[timeline]}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={dataKey}
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => `$${value / 1000}k`}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dot" hideLabel />}
            />
            <Area
              dot={{
                            fill: 'var(--color-spent)',
                        }}
              dataKey="spent"
              type="linear"
              fill="var(--color-stroke)"
              fillOpacity={0.4}
              stroke="var(--color-spent)"
            />
          </AreaChart>
        </ChartContainer>
      </WidgetContainer>
    );
}
