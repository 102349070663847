import cx from 'classnames';
import {
  debounce,
  filter,
  get,
  keyBy,
  map,
  some,
  isEmpty,
  isNumber,
  isString,
  includes,
  toLower,
  toString,
  uniqBy,
  reduce,
  find,
  first,
  forEach,
  size,
} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { ITableColumn } from '@revfluence/fresh';
import { PredefinedSegmentsForProgramQuery_segments } from '@frontend/app/queries/types/PredefinedSegmentsForProgramQuery';
import { ProjectsPageState } from '@frontend/app/containers/Projects/constants';
import {
  ColumnKey,
  AllInProgressStageTitle,
  InStageTitle,
} from '@frontend/app/containers/Projects/constants';
import { getErrorMessageFromGraphQL, generateRandomNumber } from '@frontend/utils';
import { ClientFeature, TABLE_PAGE_SIZE } from '@frontend/app/constants';
import { useMessagingContext } from '@frontend/hooks';
import {
  IRowData,
  ITableProps,
  ITableRefHandles,
  IProgram as IProject,
} from '@components';
import {
  getColumnWidth,
} from '@frontend/app/components/MemberTable/hooks';
import {
  LoadSpinner,
  Pagination,
  ModifyTagMembersModal,
  ModifyProgramMembersModal,
  ModifyCommunityMembersModal,
} from '@frontend/app/components';
import {
  useMemberFieldsWithSources,
} from '@frontend/app/hooks/memberList';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import {
  Col, Input, Row, Space, Table,
} from '@revfluence/fresh';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import {
  MemberSearchQuery_members as IMember,
  MemberSearchQueryVariables,
  MemberSearchQuery_members_owners as IOwners,
} from '@frontend/app/queries/types/MemberSearchQuery';
import {
  ITag,
  IThread,
  useClientFeatureEnabled,
  useSaveSegmentColumns,
} from '@frontend/app/hooks';
import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';
import {
  MemberSearchQuery_members_programs as IProgram,
} from '@frontend/app/queries/types/MemberSearchQuery';
import {
  ICounts,
} from '@frontend/app/containers/Projects/hooks';
import { useAuth } from '@frontend/context/authContext';
import { useMemberColumns } from '@frontend/app/components/MemberTable/hooks';
import {
  IField,
  FieldSource,
} from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { TWorklet, TWorkItem, TTask } from '@frontend/app/containers/Projects/types';
import { useRenderSelectedColumns } from './hooks/useRenderSelectedColumns';
import { MemberTableRow, useMembersTableColumns } from './hooks/useMembersTableColumns';

import { EditProjectColumns } from './EditProjectColumns';

import styles from './MembersTable.scss';

const {
  useEffect,
  useMemo,
  useState,
  useCallback,
} = React;

export enum MembersTableSelectStatus {
  All = 'All',
  None = 'None',
  Partial = 'Partial',
}

export const MSG_DURATION = 3000;

export const OWNED_BY_ME_FILTER_VALUE = 'ownedByMe';
export const LAST_MENTION_DATE_7DAYS_FILTER_VALUE = 'lastMentionDate7Days';
export const GROUP_CONTENT_REVIEW_FILTER_VALUE = 'groupContentReview';

export interface IMembersTableSearchQuery extends Omit<MemberSearchQueryVariables, 'query'> {
  query?: IMemberSearchQuery;
  selectStatus?: MembersTableSelectStatus;
}

export interface IMembersTableProps extends Partial<ITableProps> {
  className?: string;
  data?: IRowData[];
  containerHeight: number;
  onSelectedDataChange: (selectedMembers: IMember[]) => void;
  selectedMembers?: IMember[];
  renderHeaderActions?: () => React.ReactElement;
  searchQuery: IMembersTableSearchQuery;
  isLoading?: boolean;
  setDisplayedTotalRowCount?: (value: number) => void;
  selectedQuickFilter: string;
  renderEmptyPlaceholder: () => React.ReactElement;
  cellActionsByColumn?: {
    name: (memberId: IMember['id']) => void;
    lastMessage: (threadId: IThread['id']) => void;
    cta: (workItem: TWorkItem) => Promise<void>;
    task: THandleTaskSelected;
  };
  visibleColumnIds: string[];
  worklets: TWorklet[];
  counts: ICounts;
  task?: TTask;
  fetchMembers: (queryVariables: IMembersTableSearchQuery) => Promise<void>;
  members: IMember[];
  pageId?: string;
  workletSpecUri?: string;
  project?: IProject;
  projectSpecKey?: string;
  savedColumns: PredefinedSegmentsForProgramQuery_segments;
  defaultSavedColumns: PredefinedSegmentsForProgramQuery_segments;
  isLoadingPredefinedProgramSegments: boolean;
  refetchCounts: () => Promise<void>;
  onRefetchSegments: (projectId: number) => void;
}

export interface IMemberTableRefHandles extends ITableRefHandles {
  // @TODO: Revisit any type and MembersTable/Table widget implementation
  // eslint-disable-next-line
  data: any[];
  memberById: { [id: string]: IMember };
  refetch: (variables?: IMembersTableSearchQuery) => Promise<void | IMember[]>;
  selectedMembers: IMember[];
}

const TABLE_HEADER_HEIGHT = 111;
const FIXED_FIELDS = ['name'];

export type CellModalType = 'tags' | 'projects' | 'groups';

export const MembersTable = React.forwardRef<IMemberTableRefHandles, IMembersTableProps>((
  {
    className,
    data,
    containerHeight,
    onSelectedDataChange,
    selectedMembers,
    renderHeaderActions,
    searchQuery,
    isLoading,
    setDisplayedTotalRowCount,
    selectedQuickFilter,
    renderEmptyPlaceholder,
    cellActionsByColumn,
    visibleColumnIds,
    worklets,
    counts,
    task,
    fetchMembers,
    members,
    pageId,
    workletSpecUri,
    project,
    projectSpecKey,
    savedColumns,
    defaultSavedColumns,
    isLoadingPredefinedProgramSegments,
    refetchCounts,
    onRefetchSegments,
  },
) => {
  const { user } = useAuth();
  const memberColumns = useMemberColumns();
  const schemasByName = useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);
  const lastMentionDateKey = useMemo(() => get(schemasByName, 'Last Mention Date')?.schemaId, [schemasByName]);
  const { fields, appsWithFields } = useMemberFieldsWithSources(null, project?.id, true);
  const [columnsUpdated, setColumnsUpdated] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<IField[]>();
  const [tableData, setTableData] = useState<MemberTableRow[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [refreshKey, setRefreshKey] = useState<number>(generateRandomNumber());
  const [xScroll, setXScroll] = useState<number>(0);
  const [cellModalType, setCellModalType] = useState<CellModalType>();
  const [activeCellMember, setActiveCellMember] = useState<IMember>(null);
  const [saveSegmentColumns] = useSaveSegmentColumns();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();
  const [currentMembers, setCurrentMembers] = useState<IMember[]>();

  useEffect(() => {
    setCurrentMembers(members);
  }, [members]);
  const filteredFields = useMemo(() => {
    if (task) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName, ColumnKey.WorkletTaskName],
        f.field,
      ));
    }

    if (workletSpecUri) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName, ColumnKey.CTA],
        f.field,
      ));
    }

    if (pageId !== ProjectsPageState.Completed) {
      return filter(
        fields, (f) =>
        !includes([ColumnKey.DateCompleted, ColumnKey.CTA], f.field),
      );
    }

    if (pageId === ProjectsPageState.Completed) {
      return filter(
        fields, (f) =>
        !includes([ColumnKey.WorkletName, ColumnKey.WorkletTaskName], f.field),
      );
    }

    return filter(fields, (f) => !includes([ColumnKey.CTA], f.field));
  }, [fields, task, workletSpecUri, pageId]);

  const fieldById = useMemo(
    () => reduce(
      filteredFields,
      (acc, f) => acc.set(f.field, f), new Map<IField['field'], IField>(),
    ),
    [filteredFields],
  );

  useEffect(() => {
    if (!savedColumns) return;

    const fixed = map(FIXED_FIELDS, (f) => fieldById.get(f));
    const selected = map(
      (savedColumns as IPredefinedSegment).columns?.order,
      (o) => o.dbColumn || toString(o.memberFieldSchemaId) || o.projectColumn,
    );
    const filtered = filter(selected, (f) => !includes(FIXED_FIELDS, f));
    const merged = [
      ...fixed,
      ...map(filtered, (f) => fieldById.get(f)).filter(Boolean),
    ];
    setSelectedColumns(merged);
  }, [savedColumns, fieldById, setSelectedColumns]);

  const defaultColumns = useMemo(() => {
    if (!defaultSavedColumns) return [];

    const fixed = map(FIXED_FIELDS, (f) => fieldById.get(f));
    const selected = map(
      (defaultSavedColumns as IPredefinedSegment).columns?.order,
      (o) => o.dbColumn || toString(o.memberFieldSchemaId) || o.projectColumn,
    );
    const filtered = filter(selected, (f) => !includes(FIXED_FIELDS, f));
    return [
      ...fixed,
      ...map(filtered, (f) => fieldById.get(f)).filter(Boolean),
    ];
  }, [defaultSavedColumns, fieldById]);

  useEffect(() => {
    const widths = map(selectedColumns, (column) => {
      if (column?.width) return column.width;
      return getColumnWidth(column?.headerName).width;
    });
    const total = reduce(widths, (acc, w) => acc + w);
    setXScroll(total);
  }, [selectedColumns]);

  const predefinedSegmentId: string = useMemo(() => {
    if (task) {
      return `${task.taskId}+${task.workletSpecKey}_${project?.id}`;
    }

    if (workletSpecUri) {
      return `${InStageTitle}_${workletSpecUri}_${project?.id}`;
    }

    return `${AllInProgressStageTitle}_${projectSpecKey}_${project?.id}`;
  }, [
    task,
    workletSpecUri,
    project?.id,
    projectSpecKey,
  ]);

  const handleColumnOrderChange = useCallback(async (from: number, to: number) => {
    if (from === to) return;

    let updatedColumns = [];
    if (to < from) {
      updatedColumns = [
        ...selectedColumns.slice(0, to),
        selectedColumns[from],
        ...filter(
          selectedColumns.slice(to),
          (col: IField) => col.field !== selectedColumns[from].field,
        ),
      ];
    } else {
      updatedColumns = [
        ...filter(
          selectedColumns.slice(0, to + 1),
          (col: IField) => col.field !== selectedColumns[from].field,
        ),
        selectedColumns[from],
        ...selectedColumns.slice(to + 1),
      ];
    }

    const projectColumns = filter(
      updatedColumns,
      (column: IField) => column?.source === FieldSource.Project,
    );
    const memberFieldSchemaColumns = filter(
      updatedColumns,
      (column: IField) => isNumber(column?.schemaId),
    );
    const dbColumns = filter(
      updatedColumns,
      (column: IField) => !column?.schemaId && column?.source !== FieldSource.Project,
    );
    const order = map(
      updatedColumns,
      (column: IField) => ({
        dbColumn: !column?.schemaId && column?.source !== FieldSource.Project ? column?.field : null,
        memberFieldSchemaId: isNumber(column?.schemaId) ? column?.schemaId : null,
        projectColumn: column?.source === FieldSource.Project ? column.field : null,
      }),
    );
    const input = {
      dbColumns: map(dbColumns, (f) => f?.field),
      memberFieldSchemaIds: map(memberFieldSchemaColumns, (f) => f?.schemaId),
      projectColumns: map(projectColumns, (f) => f?.field),
      order,
    };

    try {
      await saveSegmentColumns({
        variables: {
          metadata: {
            predefinedSegmentId,
            columns: input,
          },
        },
      });
      setRefreshKey(generateRandomNumber());
      showSuccessMessage('Columns successfully updated', MSG_DURATION);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    predefinedSegmentId,
    selectedColumns,
    setRefreshKey,
    saveSegmentColumns,
    showSuccessMessage,
    showErrorMessage,
  ]);

  const updateTargetMember = useCallback((targetMember: IMember) => {
    setCurrentMembers((currentMembers) => reduce(
      currentMembers,
      (acc, member) => {
        if (member.id !== targetMember.id) {
          acc.push(member);
        } else {
          acc.push(targetMember);
        }
        return acc;
      },
      [],
    ));
    setRefreshKey(generateRandomNumber());
  }, [
    setCurrentMembers,
    setRefreshKey,
  ]);

  const onRelationshipAdded = useCallback((
    relationship: 'tags' | 'communities' | 'programs',
    items: ITag[] | ICommunity[] | IProgram[],
    memberIds?: number[],
  ) => {
    const memberId = first(memberIds);
    const targetMember = find(currentMembers, (member) => member.id === memberId);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const existingIds = new Set<number>(map(targetMember[relationship], (item) => (item as any).id));
    const updatedRelationship = [...(targetMember[relationship] || [])];
    forEach(items, (item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!existingIds.has((item as any).id)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedRelationship.push(item as any);
      }
    });
    updateTargetMember({
      ...targetMember,
      [relationship]: updatedRelationship,
    });
  }, [currentMembers, updateTargetMember]);

  const onTagsAdded = useCallback((tags: ITag[], memberIds?: number[]) => {
    onRelationshipAdded('tags', tags, memberIds);
  }, [onRelationshipAdded]);

  const onGroupAdded = useCallback((communities: ICommunity[], memberIds?: number[]) => {
    onRelationshipAdded('communities', communities, memberIds);
  }, [onRelationshipAdded]);

  const onProjectAdded = useCallback((projects: IProgram[], memberIds?: number[]) => {
    onRelationshipAdded('programs', projects, memberIds);
  }, [onRelationshipAdded]);

  const onTagRemoved = useCallback((tagId: number, memberId: number) => {
    const targetMember = find(currentMembers, (member) => member.id === memberId);
    const updatedTags = filter(targetMember.tags, (tag) => tag.id !== tagId);
    updateTargetMember({
      ...targetMember,
      tags: updatedTags,
    });
  }, [currentMembers, updateTargetMember]);

  const onGroupRemoved = useCallback((groupId: number, memberId: number) => {
    const targetMember = find(currentMembers, (member) => member.id === memberId);
    const updatedCommunities = filter(targetMember.communities, (community) => community.id !== groupId);
    updateTargetMember({
      ...targetMember,
      communities: updatedCommunities,
    });
  }, [currentMembers, updateTargetMember]);

  const onProjectRemoved = useCallback((projectId: number, memberId: number) => {
    if (project?.id !== projectId) {
      // user removed member from a project different from current one, update projects for member
      const targetMember = find(currentMembers, (member) => member.id === memberId);
      const updatedProjects = filter(targetMember.programs, (program) => program.id !== projectId);
      updateTargetMember({
        ...targetMember,
        programs: updatedProjects,
      });
    } else {
      // user removed member from current project, filter member out from table
      setCurrentMembers((currentMembers) => filter(
        currentMembers,
        (member) => member.id !== memberId,
      ));
      refetchCounts();
    }
  }, [
    project?.id,
    currentMembers,
    setCurrentMembers,
    updateTargetMember,
    refetchCounts,
  ]);

  const onCellModalOpen = useCallback((cellModalType: CellModalType, member: IMember) => {
    setActiveCellMember(member);
    setCellModalType(cellModalType);
  }, [setActiveCellMember, setCellModalType]);

  const columns = useMembersTableColumns({
    worklets,
    counts,
    cellActionsByColumn,
    columnsToDisplay: visibleColumnIds,
    task,
  });

  const dynamicColumns = useRenderSelectedColumns({
    worklets,
    selectedColumns,
    counts,
    cellActionsByColumn,
    projectId: project?.id,
    onTagRemoved,
    onGroupRemoved,
    onProjectRemoved,
    onCellModalOpen,
  });

  // When navigating to another page, clear search
  useEffect(() => {
    setSearchText('');
  }, [pageId, workletSpecUri]);

  const handleSearchChange = (event) => {
    const updatedSearchText = event.target.value;
    debounce((text: string) => {
      setSearchText(text);
      setPage(1);
    }, 300)(updatedSearchText);
  };

  // Refetch when searchQuery updates
  useEffect(
    () => {
      setTableData([]);
      setTotal(0);
      setPage(1);
      fetchMembers(searchQuery);
    },
    [
      setTableData,
      setTotal,
      setPage,
      fetchMembers,
      searchQuery,
    ],
  );

  const filterData = useCallback((unfilterData: MemberTableRow[], selectedFilter) => {
    switch (selectedFilter) {
      case OWNED_BY_ME_FILTER_VALUE:
        return filter(
          unfilterData,
          (member: MemberTableRow) => (
            some(member.owners, (owner: IOwners) => owner.name === user.name)
          ),
        );
      case LAST_MENTION_DATE_7DAYS_FILTER_VALUE:
        return filter(
          unfilterData,
          (member: MemberTableRow) => (!isEmpty(member[lastMentionDateKey])
            ? moment().diff(moment(member[lastMentionDateKey]), 'days') < 7
            : false),
        );
      default:
        return unfilterData;
    }
  }, [user.name, lastMentionDateKey]);

  /**
   * Merge member data with provided data by id
   */
  const dataById = useMemo(
    () => keyBy(data, (d) => d.id),
    [data],
  );

  useEffect(
    () => {
      const tableData = map(currentMembers, (member) => {
        const rowData = ({
          ...member,
          ...get(member, 'fields', {}),
          ...get(dataById, toString(member.id), {}),
        });

        return {
          ...rowData,
          id: toString(member.id),
          key: toString(member.id),
          member,
          _raw: rowData,
        };
      });
      const filteredData = filterData(tableData, selectedQuickFilter);
      setDisplayedTotalRowCount(filteredData.length);
      setTotal(filteredData.length);
      setTableData(filteredData);
    },
    [
      dataById,
      currentMembers,
      page,
      selectedQuickFilter,
      filterData,
      setDisplayedTotalRowCount,
    ],
  );

  const updatedColumns: ITableColumn<MemberTableRow> = useMemo(() => (
    isPFAColumnsEnabled ? dynamicColumns : columns
  ), [
    isPFAColumnsEnabled,
    dynamicColumns,
    columns,
  ]);

  const displayTableData = useMemo(() => {
    const start = TABLE_PAGE_SIZE * (page - 1);
    const end = TABLE_PAGE_SIZE * page;
    const lowerSearchText = toLower(searchText);
    const columnKeys = map(updatedColumns, (column) => column.key);

    const filtered = tableData.filter((row: MemberTableRow) => {
      if (searchText) {
        return !!columnKeys.find((key) => {
          if (isString(row[key]) || isNumber(row[key])) {
            const value = toLower(toString(row[key]));
            return includes(value, lowerSearchText);
          }

          if (key === 'member') {
            const value = toLower(row[key].name);
            if (includes(value, lowerSearchText)) return true;
          }

          if (key === ColumnKey.TalentAgents) {
            const searchProps = ['agentName', 'agentEmail'];
            const match = some(
              searchProps,
              (prop) => some(
                row[key],
                (agent) => includes(toLower(agent[prop]), lowerSearchText),
              ),
            );
            if (match) return true;
          }

          return false;
        });
      }

      return true;
    });

    const paginatedData = filtered.slice(start, end);
    setTotal(filtered.length);
    setDisplayedTotalRowCount(paginatedData.length);
    return filtered; // let antd handle the pagination
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableData,
    page,
    searchText,
  ]);
  const handleRowSelectionChange = useCallback((selected: {
    rowKeys: React.Key[],
    rows: MemberTableRow[]
  }) => {
    let updatedSelectedMembers = selected.rows.map((row) => row.member);
    if (searchText) {
      // update cross-pages selection list with any newly selected members in the filtered (by search text) rows
      updatedSelectedMembers = uniqBy(
        [...selectedMembers, ...updatedSelectedMembers],
        (member) => member.id,
      );
      const updatedSelectedKeys = new Set<string>(
        map(updatedSelectedMembers, (member) => member.id.toString()),
      );

      // form a list of selected rows from the filtered (by search text) rows
      const displayedSelectedRows = filter(
        displayTableData,
        (row) => updatedSelectedKeys.has(row.id),
      );

      // form a set of the updated selected rows notified by Fresh
      const updatedRowKeys = new Set<string>(selected.rows.map((row) => row.id));

      // detect deselected rows by comparing the displayed selection with the fresh updated one
      const unselectedRows = filter(displayedSelectedRows, (row) => !updatedRowKeys.has(row.id));
      const unselectedRowIds = new Set<string>(map(unselectedRows, (row) => row.id));

      // remove deselected rows from the cross-page accumulated selection list
      if (size(unselectedRowIds) > 0) {
        updatedSelectedMembers = filter(
          updatedSelectedMembers, (member) => !unselectedRowIds.has(member.id.toString()),
        );
      }
    }
    onSelectedDataChange(updatedSelectedMembers);
  }, [displayTableData, searchText, selectedMembers, onSelectedDataChange]);

  const isLoadingData = useMemo(() => (
    isLoading || (isEmpty(displayTableData) && isEmpty(searchText) && !isEmpty(currentMembers))
  ), [isLoading, currentMembers, displayTableData, searchText]);

  useEffect(() => {
    if (isLoadingData || isEmpty(displayTableData) || !containerHeight) return;

    const tableBodySelector = 'ant-table-body';
    const tableBodyContainer = document.getElementsByClassName(tableBodySelector)[0] as HTMLElement;
    if (tableBodyContainer) {
      tableBodyContainer.removeAttribute('style');
      tableBodyContainer.style.overflow = 'auto';
      tableBodyContainer.style.height = `${containerHeight - TABLE_HEADER_HEIGHT}px`;
    }
  }, [
    containerHeight,
    displayTableData,
    isLoadingData,
  ]);

  useEffect(() => {
    const refetch = async () => {
      if (columnsUpdated) {
        await onRefetchSegments(project?.id);
        setColumnsUpdated(false);
        setRefreshKey(generateRandomNumber());
      }
    };
    refetch();
  }, [
    columnsUpdated,
    project?.id,
    onRefetchSegments,
    setColumnsUpdated,
  ]);

  return (
    <div className={cx(className)}>
      {isLoadingData || xScroll === 0
        ? <LoadSpinner />
        : (
          <div className={styles.MembersTable}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Row
                className={styles.tableHeader}
                justify="space-between"
              >
                <Col>{renderHeaderActions()}</Col>
                <Col>
                  <Space>
                    {isPFAColumnsEnabled && (
                      <EditProjectColumns
                        fields={filteredFields}
                        fieldById={fieldById}
                        appsWithFields={appsWithFields}
                        predefinedSegmentId={predefinedSegmentId}
                        initialSelectedColumns={selectedColumns}
                        defaultColumns={defaultColumns}
                        onColumnsUpdated={setColumnsUpdated}
                        isLoadingSegments={isLoadingPredefinedProgramSegments}
                      />
                    )}
                    <Input.Search
                      placeholder="Search..."
                      onChange={handleSearchChange}
                      style={{ width: 200 }}
                    />
                    <Pagination
                      total={total}
                      page={page}
                      setPage={setPage}
                      pageSize={TABLE_PAGE_SIZE}
                      showTotal
                    />
                  </Space>
                </Col>
              </Row>
              {isEmpty(displayTableData)
                ? (renderEmptyPlaceholder())
                : (
                  <div className={styles.membersTableContainer}>
                    <Table.Draggable<MemberTableRow>
                      pagination={{
                        current: page,
                        total,
                        pageSize: TABLE_PAGE_SIZE,
                      }}
                      columns={updatedColumns}
                      dataSource={displayTableData}
                      key={refreshKey}
                      selectable
                      onRowSelectChange={handleRowSelectionChange}
                      onColumnDragEnd={handleColumnOrderChange}
                      showSorterTooltip={false}
                      selectedKeys={map(selectedMembers, (member) => toString(member.id))}
                      scroll={{
                        x: xScroll,
                        y: containerHeight - TABLE_HEADER_HEIGHT,
                        scrollToFirstRowOnChange: false,
                      }}
                      containerHeight={containerHeight}
                      size="middle"
                    />
                    <ModifyTagMembersModal
                      show={cellModalType === 'tags'}
                      onRequestClose={() => {
                        setCellModalType(null);
                        setActiveCellMember(null);
                      }}
                      type="add"
                      memberIds={[Number(activeCellMember?.id)]}
                      memberCount={1}
                      showNewTagButton
                      closeModalOnSave
                      onModifyComplete={onTagsAdded}
                    />
                    <ModifyProgramMembersModal
                      show={cellModalType === 'projects'}
                      onRequestClose={() => {
                        setCellModalType(null);
                        setActiveCellMember(null);
                      }}
                      type="add"
                      memberIds={[Number(activeCellMember?.id)]}
                      memberCount={1}
                      onModifyComplete={onProjectAdded}
                    />
                    <ModifyCommunityMembersModal
                      show={cellModalType === 'groups'}
                      onRequestClose={() => {
                        setCellModalType(null);
                        setActiveCellMember(null);
                      }}
                      type="add"
                      memberIds={[Number(activeCellMember?.id)]}
                      memberCount={1}
                      onModifyComplete={onGroupAdded}
                    />
                  </div>
                )}
            </Space>
          </div>
        )}
    </div>
  );
});
