import * as React from 'react';
import { RawDraftContentState } from 'draft-js';

import { Button, Space } from '@revfluence/fresh';
import { IMember } from '@frontend/app/hooks';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { CircleXmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { GetProductDetailsQuery_productDetail } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetailsQuery';
import { IProduct, IProjectConfig } from '../../../hooks';
import {
  Description, Statistics, Products, OrderRequestReviewEmail,
} from './components';
import { ICurrentRequest, ISelectedVariant, IOrderRequestStatus } from './utils';

import styles from './OrderRequestReview.styles.scss';

export interface IOrderRequestReview {
  products: IProduct[];
  fetchMoreProducts: () => void;
  currentRequest: ICurrentRequest;
  currentRequestItems: number;
  currentRequestMarketValue: number;
  projectConfig: IProjectConfig;
  member: IMember;
  searchValue: string;
  setSearchValue(value: string): void;
  isLoadingProducts: boolean;
  selectedVariants: ISelectedVariant[];
  setSelectedVariants(selectedVariants: ISelectedVariant[]): void;
  showProductSearch: boolean;
  setShowProductSearch(value: boolean): void;
  addSelectedVariantsToCurrentRequest(): void;
  toggleRemovedVariantInCurrentRequest(variantId: number): void;
  adjustVariantQuantityInCurrentRequest(variantId: number, quantity: number): void;
  orderRequestStatus: IOrderRequestStatus;
  setOrderRequestStatus(status: IOrderRequestStatus): void;
  customSend(messageParams: SendMessageInput): void | Promise<void>;
  getInitialEmailState(): [string | null, RawDraftContentState | null];
  disabled: boolean;
  notice: React.ReactNode;
  onGetMoreVariants: (product: GetProductDetailsQuery_productDetail) => void;
  resourceId: number;
  shippingAddress: string;
}

export const OrderRequestReview = (props: IOrderRequestReview) => {
  const {
    searchValue,
    setSearchValue,
    isLoadingProducts,
    products,
    fetchMoreProducts,
    member,
    currentRequest,
    currentRequestItems,
    currentRequestMarketValue,
    projectConfig,
    selectedVariants,
    setSelectedVariants,
    showProductSearch,
    setShowProductSearch,
    addSelectedVariantsToCurrentRequest,
    toggleRemovedVariantInCurrentRequest,
    adjustVariantQuantityInCurrentRequest,
    orderRequestStatus,
    setOrderRequestStatus,
    customSend,
    getInitialEmailState,
    disabled,
    notice,
    resourceId,
    shippingAddress,
  } = props;

  return (
    <div className={styles.Wizard}>
      <div className={styles.mainContentWrapper}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Description
            title="Review Order Request"
            description={`Add, remove, or change the quantity of each product that ${member.name} has requested for this project.`}
          />
          <Statistics
            maxItems={projectConfig?.quantityMax}
            maxMarketValue={projectConfig?.priceMax}
            selectedItems={currentRequestItems}
            selectedMarketValue={currentRequestMarketValue}
          />
          {notice}
          {orderRequestStatus ? (
            <OrderRequestReviewEmail
              member={member}
              orderRequestStatus={orderRequestStatus}
              getInitialEmailState={getInitialEmailState}
              customSend={customSend}
            />
          ) : (
            <Products
              disabled={disabled}
              shippingAddress={shippingAddress}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isLoadingProducts={isLoadingProducts}
              products={products}
              fetchMoreProducts={fetchMoreProducts}
              currentRequest={currentRequest}
              member={member}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              showProductSearch={showProductSearch}
              setShowProductSearch={setShowProductSearch}
              addSelectedVariantsToCurrentRequest={addSelectedVariantsToCurrentRequest}
              toggleRemovedVariantInCurrentRequest={toggleRemovedVariantInCurrentRequest}
              adjustVariantQuantityInCurrentRequest={adjustVariantQuantityInCurrentRequest}
              resourceId={resourceId}
            />
          )}
        </Space>
      </div>
      <div className={styles.footer}>
        <Space direction="horizontal" align="center" size="small">
          {orderRequestStatus ? (
            <Button
              className={styles.footerButton}
              type="default"
              icon={<CloseCircleFilled style={{ fontSize: '16px', color: '#F5222D' }} />}
              disabled={disabled}
              onClick={() => setOrderRequestStatus(undefined)}
            >
              Review Order
            </Button>
          ) : (
            <>
              <Button
                className={styles.footerButton}
                type="default"
                icon={<CircleXmarkIcon color="#F5222D" />}
                onClick={() => setOrderRequestStatus('reject')}
                disabled={disabled}
              >
                Reject Request
              </Button>
              <Button
                className={styles.footerButton}
                type="default"
                icon={<CheckCircleFilled style={{ fontSize: '16px', color: '#52C41A' }} />}
                onClick={() => setOrderRequestStatus('accept')}
                disabled={disabled}
              >
                Accept Request
              </Button>
            </>
          )}
        </Space>
      </div>
    </div>
  );
};
