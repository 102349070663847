import React from 'react';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import { largeNumberAbbreviatorWithDecimal } from '@frontend/utils';

interface MainMetricProps {
  size: 'large' | 'medium' | 'small' | 'xsmall';
  heading?: string;
  value: number;
  icon: React.ReactNode;
  metricType?: 'number' | 'currency';
  subHeading?: string;
  totalValue?: number;
  className?: string;
}

const valueTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-5xl font-bold',
        medium: 'text-4xl font-bold',
        small: 'text-2xl font-bold',
        xsmall: 'text-xl font-semibold',
      },
    },
  },
);

const currencyTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-4xl font-bold',
        medium: 'text-3xl font-bold',
        small: 'text-lg font-bold',
        xsmall: 'text-base font-semibold',
      },
    },
  },
);

const MainMetric: React.FC<MainMetricProps> = ({
  size,
  heading,
  value,
  totalValue,
  metricType = 'number',
  icon,
  subHeading,
  className,
}) => {
  const totalValueWithAbbreviation = largeNumberAbbreviatorWithDecimal(totalValue || 0);
  const valueWithAbbreviation = largeNumberAbbreviatorWithDecimal(value);

  return (
    <div className={classNames('flex flex-col gap-[1px]', className)}>
      {heading && (
        <div className="flex gap-1 items-center">
          <span>{heading}</span>
        </div>
      )}
      <div className="flex items-end gap-1">
        {metricType === 'currency' && <span className={currencyTextStyles({ size })}>$</span>}
        <span className={valueTextStyles({ size })}>{valueWithAbbreviation.value}</span>
        <span className={currencyTextStyles({ size })}>{valueWithAbbreviation.symbol}</span>
        {totalValue && (
          <span className="text-gray-500">
            {`/ ${
              metricType === 'currency'
                ? `$${totalValueWithAbbreviation.value}${totalValueWithAbbreviation.symbol}`
                : `${totalValueWithAbbreviation.value}${totalValueWithAbbreviation.symbol}`
            } Total Used`}
          </span>
        )}
      </div>
      <div className="flex items-center gap-2">
        {icon}
        {subHeading && <span className="text-gray-500">{subHeading}</span>}
      </div>
    </div>
  );
};

export default MainMetric;
