/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17lmk_157 {
  display: flex;
}

._justify-content-space-between_17lmk_161 {
  justify-content: space-between;
}

._tabular-nums_17lmk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17lmk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ComposeModal_17lmk_178 ._dialog_17lmk_178 ._title_17lmk_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17lmk_157 {
  display: flex;
}

._justify-content-space-between_17lmk_161 {
  justify-content: space-between;
}

._tabular-nums_17lmk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17lmk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ComposeModal_17lmk_178 ._dialog_17lmk_178 ._title_17lmk_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._workflowButton_17lmk_375 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._workflowButton_17lmk_375 {
  min-width: 2.25rem;
}
._workflowButton_17lmk_375 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._workflowButton_17lmk_375:focus, ._workflowButton_17lmk_375:hover {
  border-color: #167cf4;
  color: #167cf4;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailComposerModal_17lmk_428 {
  font-weight: bold;
  letter-spacing: 0.00625rem;
}

._ComposeModal_17lmk_178 {
  z-index: 1010 !important;
}
._ComposeModal_17lmk_178 ._content_17lmk_436 {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
._ComposeModal_17lmk_178 ._dialog_17lmk_178 {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 !important;
}
._ComposeModal_17lmk_178 ._dialog_17lmk_178 ._title_17lmk_178 {
  border-bottom: 0.0625rem solid #dadcde;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 3rem;
}
._ComposeModal_17lmk_178 ._dialog_17lmk_178 ._title_17lmk_178 ._composerSpace_17lmk_457 {
  width: 100%;
  justify-content: space-between;
}
._ComposeModal_17lmk_178 ._messageForm_17lmk_461 {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
._ComposeModal_17lmk_178 ._messageForm_17lmk_461._hide_17lmk_467 {
  display: none;
}

._wrapper_17lmk_471._disabled_17lmk_471 ._workflowButton_17lmk_375 {
  border-color: #bfbfbf !important;
  background-color: #f5f5f5 !important;
  cursor: no-drop;
  pointer-events: none;
}