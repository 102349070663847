import gql from 'graphql-tag';

import { ORDER_FRAGMENT } from './fragments';

export const CREATE_ORDER_REQUEST_CREATOR_INTERACTION = gql`
  mutation CreateOrderRequestCreatorInteractionMutation(
    $resourceId: Float!
    $params: ICreateOrderRequestCreatorInteractionParams!,
    $messageParams: IMessageParamsObject
  ) {
    success: createOrderRequestCreatorInteraction(resourceId: $resourceId, params: $params, messageParams: $messageParams)
  }
`;

export const APPROVE_ORDER_REQUEST_MUTATION = gql`
  mutation ApproveOrderRequestMutation(
    $orderId: String!
    $creatorOrderRequest: JSONObject!
    $workItemId: String!
    $messageParams: IMessageParamsObject!
  ) {
    order: approveOrderRequest(
      orderId: $orderId
      creatorOrderRequest: $creatorOrderRequest
      workItemId: $workItemId
      messageParams: $messageParams
    ) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REJECT_ORDER_REQUEST_MUTATION = gql`
  mutation RejectOrderRequestMutation($orderId: String!, $workItemId: String!, $messageParams: IMessageParamsObject!) {
    order: rejectOrderRequest(orderId: $orderId, workItemId: $workItemId, messageParams: $messageParams) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const BULK_SEND_ORDER_REQUEST_MUTATION = gql`
  mutation BulkSendOrderRequestMutation($params: IBulkSendOrderRequest!) {
    bulkSendOrderRequest(params: $params) {
      id
  }
}`;

export const BULK_REVIEW_ORDER_REQUEST_MUTATION = gql`
mutation BulkReviewOrderRequest($params: IBulkReviewOrderRequest!) {
  bulkReviewOrderRequest(params: $params) {
    id
  }
}`;

export const BULK_MARK_ORDERS_AS_DELIVERED_MUTATION = gql`
  mutation BulkMarkDelivered($params: IBulkMarkDelivered!) {
    orders: bulkMarkDelivered(params: $params) {
      id
    }
  }
`;
