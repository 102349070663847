import React from 'react';
import { cn } from '@/shadcn/lib/utils';

export const H4 = React.forwardRef<HTMLHeadingElement, React.ComponentPropsWithoutRef<'h4'>>(
  ({ className, children, ...props }, ref) => (
    <h4 ref={ref} className={cn('scroll-m-20 text-xl font-semibold tracking-tight', className)} {...props}>
      {children}
    </h4>
    ),
);

H4.displayName = 'H4';
