import React, { createContext, useContext, useReducer } from 'react';

export interface ProductsContextProps {
  isOpen: boolean;
  isFindAndImportOpen: boolean;
  isImportSettingsDrawerOpen: boolean;
  importSettingScreen: 'root' | 'metadata' | 'sync' | 'inventory';
  setIsOpen: (isOpen: boolean) => void;
  setIsFindAndImportOpen: (isOpen: boolean) => void;
  setIsImportSettingsDrawerOpen: (isOpen: boolean) => void;
  setImportSettingScreen: (screen: 'root' | 'metadata' | 'sync' | 'inventory') => void;
}
export type ProductContextState = Omit<
  ProductsContextProps,
  'setIsOpen' | 'setIsImportSettingsDrawerOpen' | 'setImportSettingScreen' | 'setIsFindAndImportOpen'
>;

const initialState: ProductContextState = {
  isOpen: false,
  isFindAndImportOpen: false,
  isImportSettingsDrawerOpen: false,
  importSettingScreen: 'root',
};

export const ProductsContext = createContext<ProductsContextProps>({
  ...initialState,
  setIsOpen: () => {},
  setIsFindAndImportOpen: () => {},
  setIsImportSettingsDrawerOpen: () => {},
  setImportSettingScreen: () => {},
});

export enum ActionType {
  ToggleImportModal = 'TOGGLE_IMPORT_MODAL',
  ToggleFindAndImportModal = 'TOGGLE_FIND_AND_IMPORT_MODAL',
  ToggleImportSettingsDrawer = 'TOGGLE_IMPORT_SETTINGS_DRAWER',
  SetImportSettingScreen = 'SET_IMPORT_SETTING_SCREEN',
}

export interface Action {
  type: ActionType;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  payload: any;
}

export interface ProductsProviderProps {
  children: React.ReactNode;
  initialValue?: Partial<ProductsContextProps>;
}

export const ProductsProvider = ({
  children,
  initialValue,
}: ProductsProviderProps) => {
  const reducer = (state: ProductContextState, action: Action) => {
    switch (action.type) {
      case ActionType.ToggleImportModal:
        return {
          ...state,
          isOpen: action.payload,
        };
      case ActionType.ToggleFindAndImportModal:
        return {
          ...state,
          isFindAndImportOpen: action.payload,
        };
      case ActionType.ToggleImportSettingsDrawer:
        return {
          ...state,
          isImportSettingsDrawerOpen: action.payload,
        };
      case ActionType.SetImportSettingScreen:
        return {
          ...state,
          importSettingScreen: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, { ...initialState, ...initialValue });

  const setIsOpen = (isOpen: boolean) => {
    dispatch({ type: ActionType.ToggleImportModal, payload: isOpen });
  };
  const setIsImportSettingsDrawerOpen = (isOpen: boolean) => {
    dispatch({ type: ActionType.ToggleImportSettingsDrawer, payload: isOpen });
  };
  const setImportSettingScreen = (screen: ProductContextState['importSettingScreen']) => {
    dispatch({ type: ActionType.SetImportSettingScreen, payload: screen });
  };
  const setIsFindAndImportOpen = (isOpen: boolean) => {
    dispatch({ type: ActionType.ToggleFindAndImportModal, payload: isOpen });
  };

  const memoizedValue = React.useMemo<ProductsContextProps>(
    () => ({
      ...state,
      setIsOpen,
      setIsImportSettingsDrawerOpen,
      setImportSettingScreen,
      setIsFindAndImportOpen,
    }),
    [state],
  );

  return <ProductsContext.Provider value={memoizedValue}>{children}</ProductsContext.Provider>;
};

export const useProductsContext = () => useContext(ProductsContext);
