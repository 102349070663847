import * as React from 'react';
import cx from 'classnames';
import {
 chain, findIndex, get, indexOf, isEmpty, isFunction, isNil, isNumber, keyBy, map, nth, filter, toLower,
} from 'lodash';
import { Alert, Tag, Tooltip } from 'antd';

import { AlertIcon, Button, CheckCircleIcon } from '@components';

import { useMemberFieldsWithSources } from '@frontend/app/hooks/memberList';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { FieldSelect } from '../FieldSelect';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { useUploadCSVContext } from '../hooks/useUploadCSVModalContext';
import { useReadDataPreviewFromFile } from '../hooks/useReadDataPreviewFromFile';

const {
 useCallback, useEffect, useMemo, useRef,
} = React;

import styles from './ReviewImport.scss';

interface IProps {
  modalDialogRef?: React.MutableRefObject<HTMLDivElement>;
  nextStep: () => void;
  prevStep: () => void;
  uploadIdentifier: string;
}

const FILLER = '--';

const MEMBER_READ_ONLY_FIELDS = [
  'name',
];

export const ReviewImport: React.FC<IProps> = (props) => {
  const {
    modalDialogRef,
    nextStep,
    prevStep,
    uploadIdentifier,
  } = props;

  const tableRef = useRef<HTMLTableElement>();

  const {
    fields: selectedFields,
    file,
    initFields,
    setDataSize,
    setFieldAtIndex,
    setFields,
    setHeaders,
    uploadCsvParams,
  } = useUploadCSVContext();

  // Read file
  const {
    dataPreview,
    dataSize,
    fieldCount,
    headers,
  } = useReadDataPreviewFromFile(file);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => initFields(fieldCount), [fieldCount]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDataSize(dataSize), [dataSize]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setHeaders(headers), [headers]);

  // Validate fields
  const invalidFields = useFieldValidation({
    sampleValues: dataPreview,
    selectedFields,
  });
  const identifierIndex = useMemo(
    () => {
      if (headers?.length !== selectedFields?.length) {
        return undefined;
      }
      let temp;
      for (const header of ['id', 'uid', '__uid']) {
        temp = indexOf(headers, header);
        if (temp >= 0) {
          return temp;
        }
      }
      temp = findIndex(selectedFields, (f) => f?.field === 'email');
      if (temp >= 0) {
        return temp;
      }
      return -1;
    },
    [headers, selectedFields],
  );

  // Attempt to set default fields
  const { fields } = useMemberFieldsWithSources();
  const fieldByName = useMemo(
    () => keyBy(
      filter(fields, (f) => !MEMBER_READ_ONLY_FIELDS.includes(f.field)),
      (f) => f.headerName.toLocaleLowerCase(),
    ),
    [fields],
  );
  useEffect(
    () => {
      if (isEmpty(headers)) {
        return;
      }
      setFields(
        map(headers, (field, index) => {
          const lowerField = field.toLocaleLowerCase();
          // member fields
          const memberField = get(fieldByName, lowerField);
          if (memberField) {
            return memberField;
          }
          // talent agents
            if (lowerField.includes('talent manager')) {
              return {
                headerName: 'Talent Manager',
                field: 'Talent Manager',
                type: 'SPECIAL',
              };
            }
          // no match found
          return selectedFields[index];
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headers],
  );

  // Alert message
  const renderAlert = useCallback(
    () => {
      if (identifierIndex === -1) {
        return (
          <Alert
            message={(
              <>
                Missing an
                {' '}
                <strong>Email</strong>
                ,
                <strong>id</strong>
                , or
                {' '}
                <strong>uid</strong>
                {' '}
                field
                to be used as identifier. Please assign or re-upload your csv to include it.
              </>
)}
            type="warning"
            showIcon
            className={styles.alert}
          />
        );
      }
    },
    [identifierIndex],
  );

  // Render table cells
  const renderStatusCell = useCallback(
    ({ hasError, index }) => {
      if (hasError) {
        return <AlertIcon size={22} className={styles.errorIcon} />;
      } else if (index === identifierIndex) {
        return (
          <Tooltip title={`Data will be matched based on "${headers[index]}"`}>
            <Tag
              color="blue"
              className={styles.identifierTag}
            >
              Identifier
            </Tag>
          </Tooltip>
        );
      } else if (!isNil(selectedFields[index])) {
        return <CheckCircleIcon size={22} className={styles.checkIcon} />;
      }
    },
    [headers, identifierIndex, selectedFields],
  );
  const renderDataPreviewCell = useCallback(
    (index) => {
      const preview = nth(dataPreview, index) || [];
      const fillers = preview.length < 3
        ? map(new Array(3 - preview.length), () => FILLER)
        : [];
      return map(
        [...preview, ...fillers],
        (data, j) => data && (
          <React.Fragment key={j}>
            {data === FILLER
              ? <span className={styles.filler}>{FILLER}</span>
              : data}
            {j + 1 < 3 && <br />}
          </React.Fragment>
        ),
      );
    },
    [dataPreview],
  );
  const renderFieldSelectCell = useCallback(
    ({ hasError, index }) => (
      <>
        <FieldSelect
          onFieldSelect={(field) => setFieldAtIndex(field, index)}
          index={index}
          selectedFields={selectedFields}
          filteredFields={MEMBER_READ_ONLY_FIELDS}
          className={cx(styles.fieldSelect, {
          [styles.hasError]: invalidFields[index],
        })}
        />
        {selectedFields[index] === undefined && (
        <small className={styles.unassignedMessage}>
          Unassigned field will not be imported.
        </small>
      )}
        {hasError && (
        <small className={styles.errorMessage}>
          Possible data type error
        </small>
      )}
      </>
),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invalidFields, selectedFields],
  );

  const renderRows = useCallback(
    () => map(headers, (field, index) => {
      const hasError = nth(invalidFields, index);
      const isIdField = field === 'id';
      const isUidField = field === '__uid' || field === 'uid';
      const isEmailField = selectedFields[index]?.field === 'email';
      const isNameField = toLower(field) === 'name';

      return (
        <tr
          key={index}
          className={cx({
            [styles.hasError]: hasError,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            [(styles as any).id]: isIdField,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            [(styles as any).uid]: isUidField,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            [(styles as any).email]: isEmailField,
          })}
        >
          <td>
            {renderStatusCell({ hasError, index })}
          </td>
          <td>{field}</td>
          <td className={styles.dataPreviewCell}>
            {renderDataPreviewCell(index)}
          </td>
          <td>
            {!isIdField && !isUidField && !isNameField && (
              renderFieldSelectCell({ hasError, index })
            )}
          </td>
        </tr>
      );
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      headers,
      invalidFields,
      renderDataPreviewCell,
      renderFieldSelectCell,
      renderStatusCell,
    ],
  );
  const addEvent = useEventContext();

  // Next button
  const handleNext = () => {
    if (identifierIndex === -1) {
      modalDialogRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (isFunction(nextStep)) {
      const fieldMappings = uploadCsvParams.params.memberFieldsMapping;
      const unmatchedColumns = chain(fieldMappings).values().filter((value) => isNil(value)).value();
      const matchedColumnCount = (isNumber(headers?.length) && isNumber(unmatchedColumns?.length)
        ? headers?.length - unmatchedColumns?.length
        : 0);
      addEvent(EventName.CSVUploadAssignColumnsCompleted, {
        uploadIdentifier,
        identifierColumn: headers[identifierIndex],
        totalColumnCount: headers?.length,
        matchedColumnCount,
        unmatchedColumnCount: unmatchedColumns?.length,
      });
      nextStep();
    }
  };

  const isLoading = isEmpty(headers) || isEmpty(selectedFields);

  return (
    <>
      <div
        className={cx(styles.ReviewImport, {
        [styles.isLoading]: isLoading,
      })}
      >
        {renderAlert()}
        <table
          className={styles.table}
          ref={tableRef}
        >
          <thead>
            <tr>
              <th>
                {headers?.length}
                {' '}
                Col
                {headers?.length > 1 && 's'}
              </th>
              <th>Col Header</th>
              <th>Preview</th>
              <th>Import To</th>
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
      </div>
      <footer className={styles.footer}>
        <Button
          label="Previous"
          onClick={prevStep}
          theme="light"
        />
        <Button
          label="Next"
          onClick={handleNext}
        />
      </footer>
    </>
);
};
