import React from 'react';
import { MoveDown, MoveUp, TriangleAlert } from 'lucide-react';
import { cn } from '@/shadcn/lib/utils';

interface TrendIconProps {
  trend: 'up' | 'down' | 'flat' | 'alert';
  value: string;
}

const TrendIcon: React.FC<TrendIconProps> = ({ trend, value }) => {
  const iconStyles = {
    up: 'text-green-700',
    down: 'text-red-700',
    flat: 'text-blue-700',
    alert: 'text-orange-700',
  };

  const icon = {
    up: <MoveUp size={12} />,
    down: <MoveDown size={12} />,
    flat: <MoveUp size={12} />,
    alert: <TriangleAlert size={12} />,
  };

  return (
    <div
      className={cn(
        'font-extrabold inline-flex items-center gap-[2px] bg-transparent border border-gray-300 rounded-lg p-[1px]',
        iconStyles[trend],
      )}
    >
      <span>{icon[trend]}</span>
      <span className="text-sm font-medium">{value}</span>
    </div>
  );
};

export default TrendIcon;
