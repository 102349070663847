import React from 'react';
import { WidgetContainer, DataTable } from '@frontend/app/composite-components';
import { ColumnDef } from '@tanstack/react-table';
import { Avatar, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';

import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { FileDown } from 'lucide-react';
import groupedPromoIcon from '@frontend/app/assets/images/groupedPromo.png';
import { cn } from '@/shadcn/lib/utils';

const { useState, useEffect } = React;
type RowItem = {
  id: string;
  project?: string;
  projectImage?: string;
  budgetName?: string;
  budgetImage?: string;
  splits: string[];
  sources: string[];
  budget: number;
  allocated: number;
  remaining: number;
  spend?: number;
  paidOut?: number;
  duePayments?: number;
};

const mockData: RowItem[] = [
  {
    id: '728ed52f',
    project: 'Project A',
    projectImage: groupedPromoIcon,
    budgetName: 'Marketing Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 1', 'Dept 2'],
    sources: ['Source 1', 'Source 2', 'Source 3'],
    budget: 1000,
    allocated: 500,
    paidOut: 300,
    duePayments: 100,
    remaining: 200,
    spend: 300,
  },
  {
    id: '489e1d42',
    project: 'Project B',
    projectImage: groupedPromoIcon,
    budgetName: 'Development Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 1', 'Dept 3', 'Dept 4'],
    sources: ['Source 2', 'Source 3'],
    budget: 2000,
    allocated: 1500,
    paidOut: 1000,
    duePayments: 200,
    remaining: 500,
    spend: 1000,
  },
  {
    id: '378e1d93',
    project: 'Project C',
    projectImage: groupedPromoIcon,
    budgetName: 'Operations Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 2', 'Dept 3'],
    sources: ['Source 1', 'Source 4'],
    budget: 1500,
    allocated: 1000,
    paidOut: 600,
    duePayments: 150,
    remaining: 400,
    spend: 600,
  },
  {
    id: '579f1d44',
    project: 'Project D',
    projectImage: groupedPromoIcon,
    budgetName: 'Research Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 1', 'Dept 2', 'Dept 5'],
    sources: ['Source 3', 'Source 4'],
    budget: 2500,
    allocated: 2000,
    paidOut: 1500,
    duePayments: 300,
    remaining: 500,
    spend: 1500,
  },
  {
    id: '680g1d45',
    project: 'Project E',
    projectImage: groupedPromoIcon,
    budgetName: 'IT Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 4', 'Dept 5'],
    sources: ['Source 2', 'Source 5'],
    budget: 3000,
    allocated: 2500,
    paidOut: 2000,
    duePayments: 400,
    remaining: 500,
    spend: 2000,
  },
  {
    id: '781h1d46',
    project: 'Project F',
    projectImage: groupedPromoIcon,
    budgetName: 'Sales Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 2', 'Dept 6'],
    sources: ['Source 1', 'Source 6'],
    budget: 1200,
    allocated: 800,
    paidOut: 500,
    duePayments: 100,
    remaining: 300,
    spend: 500,
  },
  {
    id: '882i1d47',
    project: 'Project G',
    projectImage: groupedPromoIcon,
    budgetName: 'Logistics Budget',
    budgetImage: groupedPromoIcon,
    splits: ['Dept 3', 'Dept 4'],
    sources: ['Source 3', 'Source 5', 'Source 6'],
    budget: 1800,
    allocated: 1300,
    paidOut: 800,
    duePayments: 200,
    remaining: 500,
    spend: 800,
  },
];

interface BudgetAllocatedWidgetProps {
  className?: string;
}

export default function BudgetAllocatedWidget({ className }: BudgetAllocatedWidgetProps) {
  const [source, setSource] = useState<string | null>('project');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RowItem[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setData(mockData);
      setLoading(false);
    }, 2000);
  }, []);

  const projectColumns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'project',
      header: () => <div className="font-semibold text-[#1F1F21]">Project</div>,
      cell: ({ row }) => {
        const projectName = row.getValue('project') as string;
        const projectImage = row.original.projectImage as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[24px] w-[24px] rounded-none">
              <AvatarImage
                src={projectImage}
                className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-[20px] text-secondary"
              />
            </Avatar>
            <span>{projectName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'sources',
      header: () => <div className="font-semibold text-[#1F1F21]">Sources</div>,
      cell: ({ row }) => {
        const sources = row.getValue('sources') as string[];
        const extraSources = sources.length > 2 ? sources.length - 2 : 0;

        return (
          <div className="flex gap-1">
            {sources.slice(0, 2).map((source, index) => (
              <Badge className="rounded-md font-normal" key={index} variant="secondary">
                {source}
              </Badge>
            ))}
            {extraSources > 0 && (
              <Badge className="rounded-md font-normal" variant="secondary">
                {`+${extraSources}`}
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'budget',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Budget</div>,
      cell: ({ row }) => {
        const budget = parseFloat(row.getValue('budget'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(budget);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'allocated',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Allocated</div>,
      cell: ({ row }) => {
        const allocated = parseFloat(row.getValue('allocated'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(allocated);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'paidOut',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Paid Out</div>,
      cell: ({ row }) => {
        const paidOut = parseFloat(row.getValue('paidOut'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(paidOut);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'duePayments',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Due Payments</div>,
      cell: ({ row }) => {
        const duePayments = parseFloat(row.getValue('duePayments'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(duePayments);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'remaining',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Remaining</div>,
      cell: ({ row }) => {
        const remaining = parseFloat(row.getValue('remaining'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(remaining);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];

  const budgetColumns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'budgetName',
      header: () => <div className="font-semibold text-[#1F1F21]">Budget</div>,
      cell: ({ row }) => {
        const budgetName = row.getValue('budgetName') as string;
        const budgetImage = row.original.budgetImage as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[24px] w-[24px] rounded-none">
              <AvatarImage
                src={budgetImage}
                className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-[20px] text-secondary"
              />
            </Avatar>
            <span>{budgetName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'splits',
      header: () => <div className="font-semibold text-[#1F1F21]">Splits to</div>,
      cell: ({ row }) => {
        const splits = row.getValue('splits') as string[];
        const extraSplits = splits.length > 2 ? splits.length - 2 : 0;

        return (
          <div className="flex gap-1">
            {splits.slice(0, 2).map((split, index) => (
              <Badge className="rounded-md font-normal" key={index} variant="secondary">
                {split}
              </Badge>
            ))}
            {extraSplits > 0 && (
              <Badge className="rounded-md font-normal" variant="secondary">
                {`+${extraSplits}`}
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'budget',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Budget</div>,
      cell: ({ row }) => {
        const budget = parseFloat(row.getValue('budget'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(budget);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'allocated',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Allocated</div>,
      cell: ({ row }) => {
        const allocated = parseFloat(row.getValue('allocated'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(allocated);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'spend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Spend</div>,
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'remaining',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Remaining</div>,
      cell: ({ row }) => {
        const remaining = parseFloat(row.getValue('remaining'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(remaining);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];

  const columns = source === 'project' ? projectColumns : budgetColumns;

  const widgetActions = (
    <div className="flex items-center gap-2">
      <Button size="icon" variant="outline">
        <FileDown className="h-4 w-4" />
      </Button>
      <ToggleGroup
        className="border border-gray-300 rounded-lg overflow-hidden inline-flex"
        type="single"
        value={source}
        onValueChange={(value) => setSource(value)}
      >
        <ToggleGroupItem value="project">Project</ToggleGroupItem>
        <ToggleGroupItem value="budget">Budget</ToggleGroupItem>
      </ToggleGroup>
    </div>
  );

  if (loading) {
    return (
      <WidgetContainer
        widgetTitle="Total Allocated"
        className={cn(className, '')}
        bgColor="bg-white"
        textColor="text-gray-700"
      >
        <Skeleton className="h-6 w-full mb-4" />
        <Skeleton className="h-6 w-full mb-4" />
        <Skeleton className="h-6 w-full mb-4" />
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer
      widgetTitle="Total Allocated"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      <DataTable columns={columns} data={data} />
    </WidgetContainer>
  );
}
