import React, { useEffect, useState } from 'react';
import {
 Button, Checkbox, Col, Drawer, Input, InputNumber, Row, Skeleton, Space, Tooltip, Typography,
} from '@revfluence/fresh';
import { MinusIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import {
  GET_SELECTION_CRITERIA,
  GET_SELECTION_CRITERIA_BY_ID,
} from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash';
import { useMessagingContext } from '@frontend/hooks';
import { CatalogType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { useSelectionCriteriaContext } from './SelectionCriteriaContext';
import styles from './SelectionCriteriaDrawer.scss';
import { useCreateSelectionCriteria } from '../hooks/useCreateSelectionCriteria';
import { useGetSelectionCriteriaById } from '../hooks/useGetSelectionCriteriaById';
import { useUpdateSelectionCriteria } from '../hooks/useUpdateSelectionCriteria';
import { AdvancedRules } from './AdvancedRules';
import { useCatalogDetailsContext } from '../CatalogDetails/CatalogDetailsContext';
import { useCreateCatalog } from '../hooks/useCreateCatalog';
import { pfaV2Routes } from '../../routes';

const { Text, Title } = Typography;

interface UseValidationProps {
  label: string;
  description: string;
  hasDescription: boolean;
  hasQuantityLimit: boolean;
  hasPriceLimit: boolean;
  minQuantity: number | null;
  maxQuantity: number | null;
  maxPrice: number | null;
}
const useValidation = ({
  label,
  description,
  hasDescription,
  hasQuantityLimit,
  hasPriceLimit,
  minQuantity,
  maxPrice,
}: UseValidationProps) => {
  const { conditions } = useSelectionCriteriaContext();
  let isValid = true;
  let errorMessage = '';
  if (!label) {
    isValid = false;
    errorMessage = 'Label is required';
  } else if (hasDescription && !description) {
    isValid = false;
    errorMessage = 'Description is required';
  } else if (!hasQuantityLimit && !hasPriceLimit && conditions.length === 0) {
    isValid = false;
    errorMessage = 'At least one filter is required to save this rule';
  } else if (hasQuantityLimit && !minQuantity) {
    isValid = false;
    errorMessage = 'Quantity limit is required';
  } else if (hasPriceLimit && !maxPrice) {
    isValid = false;
    errorMessage = 'Price limit is required';
  } else {
    for (const condition of conditions) {
      if (condition.categories.length === 0
        && condition.collections.length === 0
        && condition.productTypes.length === 0
        && condition.tags.length === 0
        && condition.vendors.length === 0
        && condition.options.every((option) => option.values.length === 0)
        && !condition.priceMin
        && !condition.priceMax) {
        isValid = false;
        errorMessage = 'At least one filter is required in each condition';
        break;
      }
    }
  }
  return { isValid, errorMessage };
};

export const SelectionCriteriaDrawer = () => {
  const {
    isDrawerOpen, setIsDrawerOpen, selectedCriteriaId, conditions, setConditions,
  } = useSelectionCriteriaContext();

  const {
    showErrorMessage,
  } = useMessagingContext();

  const { catalogId } = useParams<{ catalogId: string }>();
  const history = useHistory();
  const { title, status, description: catalogDescription } = useCatalogDetailsContext();
  const { createCatalog } = useCreateCatalog({
    onCompleted: (data) => {
      const path = pfaV2Routes.settings.brandCatalogCollections.replace(':catalogId', String(data?.createBrandCatalog?.id));
      history.push({ pathname: path, search: '?screen=selection-criteria' });
    },
    onError: (error) => {
      console.error(error);
      showErrorMessage(error.message || 'Failed to create catalog');
    },
    refetchQueries: [GET_BRAND_CATALOGS_QUERY],
  });
  const { criteria: savedCriteria, loading: isGetRuleLoading } = useGetSelectionCriteriaById({
    variables: {
      id: selectedCriteriaId,
    },
    skip: !selectedCriteriaId,
  });

  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [hasDescription, setHasDescription] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const [hasQuantityLimit, setHasQuantityLimit] = useState(false);
  const [hasPriceLimit, setHasPriceLimit] = useState(false);

  const [minQuantity, setMinQuantity] = useState<number | null>(null);
  const [maxQuantity, setMaxQuantity] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);

  const { createSelectionCriteria, loading: isCreateRuleLoading } = useCreateSelectionCriteria({
    onError: (error) => {
      console.error(error);
      showErrorMessage(error.message || 'Failed to create selection criteria rule');
    },
  });
  const { updateSelectionCriteria, loading: isUpdateRuleLoading } = useUpdateSelectionCriteria({
    onError: (error) => {
      console.error(error);
      showErrorMessage(error.message || 'Failed to update selection criteria rule');
    },
  });

  const handleSaveRule = async () => {
    let newCatalogId = Number(catalogId);
    if (catalogId === 'new') {
      const { data: catalog } = await createCatalog({
        variables: {
          input: {
            name: title,
            type: CatalogType.CUSTOM,
            status,
            description: catalogDescription,
          },
        },
      });
      if (!catalog) {
        return;
      }
      newCatalogId = (catalog.createBrandCatalog.id);
    }
    createSelectionCriteria({
      variables: {
        input: {
          brandCatalogId: newCatalogId,
          description: hasDescription ? description : null,
          label,
          maxPrice: hasPriceLimit ? maxPrice : null,
          maxQuantity: hasQuantityLimit ? maxQuantity : null,
          minQuantity: hasQuantityLimit ? minQuantity : null,
          settings: {
            setPriceLimit: hasPriceLimit,
            setQuantityLimit: hasQuantityLimit,
            showDescription,
          },
          conditions: conditions.map((condition) => ({
            categories: condition.categories,
            collections: condition.collections.map((collection) => ({
              id: collection.id,
              name: collection.name,
            })),
            conditionName: condition.conditionName,
            conditionOperation: condition.operation,
            isRequired: condition.isRequired,
            options: condition.options.map((option) => ({
              name: option.name,
              values: option.values,
            })).filter((option) => option.values.length > 0),
            priceMax: condition.priceMax,
            priceMin: condition.priceMin,
            productTypes: condition.productTypes,
            quantity: condition.quantity,
            quantityType: condition.productQuantityType,
            tags: condition.tags,
            vendors: condition.vendors,
          })),
        },
      },
      onCompleted: () => {
        handleCancel();
      },
      refetchQueries: [GET_SELECTION_CRITERIA, GET_SELECTION_CRITERIA_BY_ID],
    });
  };

  const handleEditRule = () => {
    updateSelectionCriteria({
      variables: {
        id: selectedCriteriaId,
        input: {
          brandCatalogId: Number(catalogId),
          description: hasDescription ? description : null,
          label,
          maxPrice: hasPriceLimit ? maxPrice : null,
          maxQuantity: hasQuantityLimit ? maxQuantity : null,
          minQuantity: hasQuantityLimit ? minQuantity : null,
          settings: {
            setPriceLimit: hasPriceLimit,
            setQuantityLimit: hasQuantityLimit,
            showDescription,
          },
          conditions: conditions.map((condition) => ({
            id: condition.conditionId,
            categories: condition.categories,
            collections: condition.collections.map((collection) => ({
              id: collection.id,
              name: collection.name,
            })),
            conditionName: condition.conditionName,
            conditionOperation: condition.operation,
            isRequired: condition.isRequired,
            options: condition.options.map((option) => ({
              name: option.name,
              values: option.values,
            })).filter((option) => option.values.length > 0),
            priceMax: condition.priceMax,
            priceMin: condition.priceMin,
            productTypes: condition.productTypes,
            quantity: condition.quantity,
            quantityType: condition.productQuantityType,
            tags: condition.tags,
            vendors: condition.vendors,
          })),
        },
      },
      onCompleted: () => {
        handleCancel();
      },
      refetchQueries: [GET_SELECTION_CRITERIA, GET_SELECTION_CRITERIA_BY_ID],
    });
  };

  const handleSave = () => {
    if (selectedCriteriaId) {
      handleEditRule();
    } else {
      handleSaveRule();
    }
  };

  const handleCancel = () => {
    setIsDrawerOpen({ isDrawerOpen: false, selectedCriteriaId: null });
    setLabel('');
    setDescription('');
    setHasDescription(false);
    setShowDescription(false);
    setHasQuantityLimit(false);
    setHasPriceLimit(false);
    setMinQuantity(null);
    setMaxQuantity(null);
    setMaxPrice(null);
    setConditions([]);
  };

  useEffect(() => {
    if (selectedCriteriaId && savedCriteria) {
      setLabel(savedCriteria.label);
      setDescription(savedCriteria.description);
      setHasDescription(!!savedCriteria.description);
      setShowDescription(savedCriteria.settings.showDescription);
      setHasQuantityLimit(!isNil(savedCriteria.minQuantity) || !isNil(savedCriteria.maxQuantity));
      setHasPriceLimit(!isNil(savedCriteria.maxPrice));
      setMinQuantity(savedCriteria.minQuantity);
      setMaxQuantity(savedCriteria.maxQuantity);
      setMaxPrice(savedCriteria.maxPrice);
      setConditions(savedCriteria.conditions.map((condition) => ({
        conditionId: condition.id || uuidv4(),
        categories: condition.categories,
        collections: condition.collections.map((collection) => ({
          id: collection.id,
          name: collection.name,
        })),
        conditionName: condition.conditionName,
        operation: condition.conditionOperation,
        isRequired: condition.isRequired,
        options: condition.options?.map((option) => ({
          name: option.name,
          values: option.values,
        })) ?? [],
        priceMax: condition.priceMax,
        priceMin: condition.priceMin,
        productTypes: condition.productTypes,
        productQuantityType: condition.quantityType,
        quantity: condition.quantity,
        tags: condition.tags,
        vendors: condition.vendors,
      })));
    }
  }, [selectedCriteriaId, savedCriteria, isDrawerOpen, setConditions]);

  const { isValid, errorMessage } = useValidation({
    label,
    description,
    hasDescription,
    hasQuantityLimit,
    hasPriceLimit,
    minQuantity,
    maxQuantity,
    maxPrice,
  });

  return (
    <Drawer
      width={640}
      title={(
        <Row justify="center" align="middle" className={styles.drawerTitle}>
          <Col>
            <Title level={4}>{selectedCriteriaId ? 'Edit Rule' : 'Create New Rule'}</Title>
          </Col>
        </Row>
      )}
      footer={(
        <Row justify="center" align="middle" gutter={24}>
          <Col>
            <Button size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Tooltip title={errorMessage}>
              <Button
                type="primary"
                size="large"
                onClick={handleSave}
                loading={isCreateRuleLoading || isUpdateRuleLoading}
                disabled={!isValid}
              >
                Save Rule
              </Button>
            </Tooltip>
          </Col>
        </Row>
      )}
      open={isDrawerOpen}
      className={styles.SelectionCriteriaDrawer}
      onClose={handleCancel}
    >
      {isGetRuleLoading && selectedCriteriaId && <Skeleton />}
      {(!selectedCriteriaId || !isGetRuleLoading) && (
        <Space direction="vertical" size="large">
          <Space direction="vertical">
            <Text>Label</Text>
            <Input placeholder="Enter a label for this rule" value={label} onChange={(e) => setLabel(e.target.value)} />
            {hasDescription ? (
              <>
                <Button
                  type="link"
                  className={styles.addDescriptionBtn}
                  icon={<MinusIcon />}
                  onClick={() => {
                    setHasDescription(false);
                    setDescription('');
                  }}
                >
                  Remove Description
                </Button>
                <Input.TextArea
                  placeholder="Add a description here..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Checkbox checked={showDescription} onChange={(e) => setShowDescription(e.target.checked)}>
                  Show description to creators
                </Checkbox>
              </>
            ) : (
              <Button
                type="link"
                icon={<PlusIcon />}
                className={styles.addDescriptionBtn}
                onClick={() => setHasDescription(true)}
              >
                Add Description
              </Button>
            )}
          </Space>
          <Space direction="vertical" size="middle">
            <Text weight="semibold">Cart Level Settings</Text>
            <Space direction="vertical">
              <Checkbox checked={hasQuantityLimit} onChange={(e) => setHasQuantityLimit(e.target.checked)}>
                Set product quantity limit on cart level
              </Checkbox>
              {hasQuantityLimit && (
                <Space align="start" className={styles.numberInputContainer}>
                  <Space size={0} direction="vertical">
                    <InputNumber
                      className={styles.numberInput}
                      value={minQuantity}
                      onChange={(e) => setMinQuantity(e ? Number(e) : null)}
                      min={1}
                      max={1000}
                    />
                    <Text type="secondary">Min</Text>
                  </Space>
                  <Text className={styles.minMaxDivider}>-</Text>
                  <Space size={0} direction="vertical">
                    <InputNumber
                      className={styles.numberInput}
                      value={maxQuantity}
                      onChange={(e) => setMaxQuantity(e ? Number(e) : null)}
                      min={1}
                      max={1000}
                    />
                    <Text type="secondary">Max (Optional)</Text>
                  </Space>
                </Space>
              )}
            </Space>
            <Space direction="vertical">
              <Checkbox checked={hasPriceLimit} onChange={(e) => setHasPriceLimit(e.target.checked)}>
                Set maximum price limit on cart level
              </Checkbox>
              {hasPriceLimit && (
                <InputNumber
                  className={`${styles.numberInput} ${styles.numberInputContainer}`}
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(Number(e))}
                  min={1}
                  max={1000000}
                />
              )}
            </Space>
          </Space>
          <AdvancedRules />
        </Space>
      )}
    </Drawer>
  );
};
