import { OfferImage } from '@frontend/applications/AffiliatesApp/components';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { OFFER_SOURCE, OFFER_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  Alert,
  Button, Drawer, Row, Tag, Typography,
} from '@revfluence/fresh';
import { ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import React, { useMemo, useState } from 'react';
import { ManageOffer } from '@frontend/applications/AffiliatesApp/components/ManageOffer/ManageOffer';
import { TMember } from '@frontend/applications/AffiliatesApp/components/MembersWizard/types';
import { isEmpty, isNull } from 'lodash';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import styles from './SelectOffer.scss';
import { getProjectMemberData } from '../../hooks/useProjectMemberData';

interface IProps {
  offers: GetOfferById_offer[];
  openSelectedOffer: boolean;
  closeSelectedOffer: () => void;
  selectedMemberIds: number[];
  setIsVisibleSelectOffer: (value: boolean) => void;

}
const { Title, Text } = Typography;

export const SelectOffers: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    offers, openSelectedOffer, closeSelectedOffer, selectedMemberIds, setIsVisibleSelectOffer,
  } = props;

  const [offer, setOffer] = useState<GetOfferById_offer>();
  const [selectedMember, setSelectedMember] = useState<TMember[] | undefined>();
  const [isVisibleManageOffer, setIsVisibleManageOffer] = useState(false);
  const offerSource = useMemo(() => (offer && (offer.promos.length ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE)), [offer]);
  const migrateToGraphQL = useClientFeatureEnabled(ClientFeature.MIGRATE_TO_GRAPHQL);

  const handleOffers = (id) => {
    const selectedOffer = offers.find((offer) => offer.id === id);
    setOffer(selectedOffer);

    const offerMembers = getProjectMemberData({ offer: selectedOffer });

    const selectedMember = offerMembers.filter((member) => selectedMemberIds.includes(member.id));
    setSelectedMember(selectedMember);

    setIsVisibleManageOffer(true);
    closeSelectedOffer();
  };

  const closeManageOffer = () => {
    setIsVisibleManageOffer(false);
  };
  return (
    <>
      <Drawer
        title="Manage Offers"
        placement="right"
        onClose={closeSelectedOffer}
        open={openSelectedOffer}
        width={600}
        headerStyle={{ textAlign: 'center' }}
      >
        {!isEmpty(offers) ? (
          <div className={styles.manageOfferwrapper}>
            <div className={styles.manageOfferButtons}>
              <Text>Below is the list of offers associated with the selected members. Choose the offer you wish to edit.</Text>
              <Title level={5}>Select Offer</Title>
              {offers.map((offer) => (
                <Button className={styles.SelectOfferButtonWrapper} onClick={() => handleOffers(offer.id)} key={offer.id} disabled={(offer.promos.length ? offer.promos[0].status === OFFER_STATUS.PAUSED : offer.links[0].status === OFFER_STATUS.PAUSED) || !isNull(offer.archivedDate)}>
                  <Row
                    align="middle"
                    justify="space-between"
                    className={styles.buttonsAction}
                  >
                    <Row align="middle" gutter={12}>
                      <OfferImage
                        className={styles.img}
                        imageUrl={offer?.imageUrl}
                        source={offer.promos.length ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
                      />
                      <div className={styles.buttonTitle}>
                        <Title level={5}>{offer.name}</Title>
                      </div>
                    </Row>
                    <Row align="middle">
                      {!isNull(offer.archivedDate) && (
                        <div>
                          <Tag color="warning">Archived</Tag>
                        </div>
                      )}
                      <ChevronRightIcon />
                    </Row>
                  </Row>
                  {(offer.promos.length ? offer.promos[0].status : offer.links[0].status) === OFFER_STATUS.PAUSED ? (
                    <Alert type="info" message="Offer is currently paused, and therefore cannot be edited in its present state. To make any edits to the offer for members, you will first need to change its status from paused to active." />
                  ) : null}
                </Button>
              ))}
            </div>
          </div>
        ) : <Alert type="warning" message="No offer is currently connected. Please link an offer to this project to proceed." />}
      </Drawer>
      {(offer && isVisibleManageOffer) && (
        <ManageOffer
          onComplete={closeManageOffer}
          visible={isVisibleManageOffer}
          onClose={closeManageOffer}
          offer={offer}
          selectedMemberIds={selectedMemberIds}
          selectedMembers={selectedMember}
          offerSource={offerSource}
          isWorkFlow
          migrateToGraphQL={migrateToGraphQL}
          setIsVisibleSelectOffer={setIsVisibleSelectOffer}
        />
      )}
    </>
  );
});
