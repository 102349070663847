import React from 'react';
import { SectionHeader, DashboardLayout } from '@frontend/app/composite-components';
import { LoadSpinner } from '@components';
import widgetMapper from '@frontend/app/utils/widgetMapper';

interface IBudgetTabProps {
  budgetId?: number;
  fiscalYear?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dashboardConfg?: any;
}

export const BudgetTab: React.FC<IBudgetTabProps> = (props) => {
  const config = props?.dashboardConfg?.find((config) => config.name === 'budget_dashboard') || null;
  const { budgetId, fiscalYear } = props;
  if (!config) {
    return (
      <div className="px-4 bg-secondary h-full flex">
        <LoadSpinner />
      </div>
    );
  }
  return (
    <div className="px-4 bg-secondary">
      {config?.sections?.map((section) => (
        <section key={section.sectionId}>
          <SectionHeader title={section.sectionName} description={section.description} />
          <DashboardLayout
            components={section?.widgets.map((widget) => ({
              content: widgetMapper({ type: widget.type, props: { budgetId, fiscalYear } }),
              columns: widget.width,
            }))}
            gap="4"
          />
        </section>
      ))}
    </div>
  );
};
