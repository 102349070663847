import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { WidgetContainer, DataTable } from '@frontend/app/composite-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Avatar, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import groupedPromoIcon from '@frontend/app/assets/images/groupedPromo.png';
import { cn } from '@/shadcn/lib/utils';

const { useState, useEffect } = React;
type RowItem = {
  id: string;
  name: string;
  profileImage: string;
  totalSpend: number;
};

const mockData: RowItem[] = [
    {
      id: '1',
      name: 'Alice Johnson',
      profileImage: groupedPromoIcon,
      totalSpend: 3200,
    },
    {
      id: '2',
      name: 'Bob Smith',
      profileImage: groupedPromoIcon,
      totalSpend: 4500,
    },
    {
      id: '3',
      name: 'Carol Davis',
      profileImage: groupedPromoIcon,
      totalSpend: 2100,
    },
  ];

interface TopSpendWidgetProps {
  className?: string;
}

export default function TopSpendWidget({ className }: TopSpendWidgetProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RowItem[]>([]);
  useEffect(() => {
    setTimeout(() => {
      setData(mockData);
      setLoading(false);
    }, 2000);
  }, []);

  const columns: ColumnDef<RowItem>[] = [
    {
        accessorKey: 'name',
        header: () => <div className="font-semibold text-[#1F1F21]">Name</div>,
        cell: ({ row }) => {
          const name = row.getValue('name') as string;
          const profileImage = row.original.profileImage as string;
          return (
            <div className="flex gap-1 items-center">
              <Avatar className="h-[20px] w-[20px] rounded-full">
                <AvatarImage
                  src={profileImage}
                  className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-[20px] text-secondary"
                />
              </Avatar>
              <span>{name}</span>
            </div>
          );
        },
      },
    {
      accessorKey: 'totalSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Total Spend</div>,
      cell: ({ row }) => {
        const totalSpend = parseFloat(row.getValue('totalSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalSpend);
        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];
  if (loading) {
    return (
      <WidgetContainer
        widgetTitle="Top Spend on"
        className={cn(className, '')}
        bgColor="bg-white"
        textColor="text-gray-700"
      >
        <Skeleton className="h-6 w-full mb-4" />
        <Skeleton className="h-6 w-full mb-4" />
        <Skeleton className="h-6 w-full mb-4" />
      </WidgetContainer>
    );
  }
  return (
    <WidgetContainer
      widgetTitle="Top Spend on"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      <DataTable columns={columns} data={data} />
    </WidgetContainer>
  );
}
