import React from 'react';
import {
  TotalBudgetWidget,
  TotalAllocatedWidget,
  TotalSpentWidget,
  BudgetAllocatedWidget,
  SourceLevelSpendWidget,
  AverageSpendOnCreatorWidget,
  SpendByCreatorSegmentWidget,
  TopSpendWidget,
  SpendActivityWidget,
} from '../widgets';

interface WidgetConfig {
  type: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  props?: any;
}

const widgetMapper = (widgetConfig: WidgetConfig): React.ReactNode => {
  const { type, props } = widgetConfig;

  switch (type) {
    case 'budget_total_widget':
      return <TotalBudgetWidget {...props} />;
    case 'budget_total_allocated':
      return <TotalAllocatedWidget {...props} />;
    case 'budget_total_spent':
      return <TotalSpentWidget {...props} />;
    case 'budget_allocated_to':
      return <BudgetAllocatedWidget {...props} />;
    case 'budget_total_spend':
      return <SourceLevelSpendWidget {...props} />;
    case 'budget_average_spend_on_creators':
      return <AverageSpendOnCreatorWidget {...props} />;
    case 'budget_spend_by_creator_segment':
      return <SpendByCreatorSegmentWidget {...props} />;
    case 'budget_total_spend_on':
      return <TopSpendWidget {...props} />;
    case 'budget_spend_activity':
      return <SpendActivityWidget {...props} />;
    default:
      return null;
  }
};

export default widgetMapper;
