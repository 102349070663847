// eslint-disable-next-line max-len
export const CustomProjectImageUrl = 'https://storage.googleapis.com/aspirex-static-files/projects-app/Custom_Project.png';
export const CustomProjectTemplateName = 'Create a Custom Project';

export const ProjectsRouteRoot = '/projects';
export const AllInProgressStageTitle = 'All In Progress';
export const InStageTitle = 'In Stage';

export enum ProjectsPageState {
  AllInProgress = 'AllInProgress',
  App = 'App',
  Applicants = 'Applicants',
  Completed = 'Completed',
  Invited = 'Invited',
  Rejected = 'Rejected',
  // Segments = 'Segments', // TODO: Not used yet?
  Task = 'Task',
  Overview = 'Overview',
  FindCreators = 'FindCreators',
  GroupContentReview = 'GroupContentReview',
  Worklet = 'Worklet',
}

export enum Task {
  // SendContractWorkletSpecification
  SubmitContractTask = 'submit_contract_task',
  SendContractTask = 'send_contract_task',
  MemberSignContractTask = 'member_sign_contract_task',
  BrandSignContractTask = 'brand_sign_contract_task',

  // PaymentWorkletSpecification
  SendPaymentTask = 'send_payment_task',

  // SendTermsWorkletSpecification
  SendTermsTask = 'send_terms_task',
  WaitingForAgreementTask = 'waiting_for_agreement_task',
  FixErrorSendingTermsTask = 'fix_error_sending_terms_task',
  ReviewTermsTask = 'review_terms_task',

  // ReviewContentWorkletSpecification
  WaitingForContentTask = 'waiting_for_content_task',
  ReviewContentTask = 'review_content_task',
  WaitingForPostsTask = 'waiting_for_posts_task',

  // ConfirmAddressWorkletSpecification, SendProductWorkletSpecification
  ConfirmAddressesTask = 'confirm_addresses_task',
  WaitingForAddressConfirmationTask = 'waiting_for_address_confirmation_task',
  SubmitOrderTask = 'submit_order_task',

  // CreatorProductSelectionWorkletSpecification
  ReviewOrderRequestTask = 'review_order_request_task',
  ProcessingCreatorOrderTask = 'processing_creator_order_task',
  CreatorOrderInTransitTask = 'creator_order_in_transit_task',

  // CreatorProductSelectionWorkletSpecification V2
  ReviewOrderRequestTaskV2 = 'pfa_v2_review_order_request_task',
  ProcessingCreatorOrderTaskV2 = 'pfa_v2_processing_creator_order_task',
  CreatorOrderInTransitTaskV2 = 'pfa_v2_creator_order_in_transit_task',

  // ProductFulfillment
  ProcessingOrder = 'send_order_task',
  ProductInTransit = 'order_in_transit_task',

  // OfflineCreatorProductSelectionWorkletSpecification
  OfflineReviewOrderRequestTask = 'offline_review_order_request_task',
  OfflineFulfillOrderTask = 'offline_fulfill_order_task',
  OfflineOrderInTransitTask = 'offline_order_in_transit_task',

  // SalesLinkWorkletSpecification, PromoCodesWorkletSpecification, SalesLinkPromoCodesWorkletSpecification
  FixErrorPromoTask = 'fix_error_promo_task',
  SendLinkTask = 'send_link_task',
  SendPromoTask = 'send_promo_task',
  SendLinkAndPromoTask = 'send_link_and_promo_task',
}

/**
 * Column keys for the Projects table
 * Special fields prefixed with work_item__ to prevent conflicts
 */
export enum ColumnKey {
  // defaults
  Name = 'name',
  Email = 'email',
  CTA = 'work_item__cta',
  DateAdded = 'work_item__date_added',
  DateCompleted = 'work_item__date_completed',
  DaysInStage = 'work_item__days_in_stage',
  LastMessage = 'lastMessage',
  Instagram = 'instagram',
  TikTok = 'tiktok',
  ID = 'id',
  Member = 'member',
  WorkletName = 'work_item__worklet_name',
  WorkletTaskName = 'work_item__worklet_task_name',
  TaskName = 'work_item__task_name',
  Owners = 'owners',
  Orders = 'pfa__orders',
  OrdersSummary = 'pfa__ordersSummary',
  ProgramOrdersSummaries = 'pfa__programOrdersSummaries',

  // member_sign_contract_task, brand_sign_contract_task
  PendingContracts = 'pending_contracts',

  // send_payment_task
  PaypalAddress = 'payPalAddress',
  LastPaymentSent = 'last_payment_sent',

  // waiting_for_agreement_task, fix_error_sending_terms_task
  Message = 'message',

  // review_terms_task
  Deliverables = 'deliverables',
  Price = 'price',

  // waiting_for_content_task, review_content_task, waiting_for_posts_task
  NextDeadline = 'next_deadline',
  OpenDeliverables = 'open_deliverables',
  CompletedDeliverables = 'completed_deliverables',

  // confirm_addresses_task, waiting_for_address_confirmation_task, submit_order_task
  Address = 'Address',
  Address1 = 'Address1',
  Address2 = 'Address2',
  City = 'City',
  State = 'State',
  PostalCode = 'PostalCode',

  // confirm_addresses_task, waiting_for_address_confirmation_task,
  // submit_order_task, review_order_request_task, offline_review_order_request_task,
  Country = 'Country',
  ShippingCountry = 'country',

  // review_order_request_task
  OrderStatus = 'status',

  // order_in_transit_task, creator_order_in_transit_task
  OrderNumber = 'pfa__orderNumber',

  // order_in_transit_task, creator_order_in_transit_task
  OrderDate = 'pfa__orderDate',

  // order_in_transit_task, creator_order_in_transit_task
  OrderFulfillmentStatus = 'pfa__fulfillmentStatus',

  // order_in_transit_task, creator_order_in_transit_task
  OrderShipmentStatus = 'pfa__orderShipmentStatus',

  // order_in_transit_task, creator_order_in_transit_task
  OrderTrackingNumber = 'pfa__orderTrackingNumber',

  // offline_review_order_request_task, offline_fulfill_order_task
  Quantity = 'quantity',
  Products = 'products',

  // review_order_request_task
  TotalOrderedProductsInPending = 'pfa__productShippedCountInPending',
  TotalOrderedMarketValueInPending = 'pfa__totalOrdersCostInPending',

  TotalShippedProducts = 'pfa__productShippedCount',
  TotalShippedMarketValue = 'pfa__totalOrdersCost',

  // review_order_request_task, offline_review_order_request_task
  ShippingAddress = 'address',

  // offline_order_in_transit_task
  ShipmentStatus = 'shipmentStatus',
  TrackingNumber = 'trackingNumber',

  // fix_error_promo_task, send_promo_task, send_link_and_promo_task
  PromoCode = 'promoCode',

  // fix_error_promo_task
  PromoCodeStatus = 'promoCodeStatus',

  // send_link_task, send_link_and_promo_task
  AffiliateLink = 'affiliateLink',

  Programs = 'programs',
  Communities = 'communities',
  Tags = 'tags',
  Highlights = 'highlights',
  TalentAgents = 'talentAgents',
  InvitedPrograms = 'invitedPrograms',
  SubmittedPrograms = 'submittedPrograms',
  RejectedPrograms = 'rejectedPrograms',
  Important = 'isImportant',
  Source = 'source',
  ApplicantSource = 'applicantSource',
}

export const ColumnConfig = {
  [ColumnKey.Member]: {
    key: ColumnKey.Member,
    dataIndex: 'member',
    title: 'Name',
    width: 120,
    minWidth: 120,
    maxWidth: 300,
    fixed: 'left',
  },
  [ColumnKey.Email]: {
    key: ColumnKey.Email,
    dataIndex: ColumnKey.Email,
    title: 'Email',
    width: 150,
    minWidth: 120,
    maxWidth: 250,
  },
  [ColumnKey.LastMessage]: {
    key: ColumnKey.LastMessage,
    dataIndex: 'member',
    title: 'Last Message',
    width: 140,
    maxWidth: 300,
    minWidth: 140,
  },
  [ColumnKey.WorkletName]: {
    key: ColumnKey.WorkletName,
    dataIndex: ColumnKey.WorkletName,
    title: 'Stage',
    width: 130,
    minWidth: 130,
    maxWidth: 200,
  },
  [ColumnKey.CTA]: {
    title: 'Action',
    dataIndex: ColumnKey.CTA,
    key: ColumnKey.CTA,
    width: 130,
    minWidth: 130,
    maxWidth: 200,
  },
  [ColumnKey.WorkletTaskName]: {
    key: ColumnKey.WorkletTaskName,
    dataIndex: ColumnKey.WorkletTaskName,
    title: 'Status',
    width: 110,
    minWidth: 110,
    maxWidth: 150,
  },
  [ColumnKey.Instagram]: {
    key: ColumnKey.Instagram,
    title: 'Instagram',
    dataIndex: '_raw',
    width: 80,
    maxWidth: 150,
  },
  [ColumnKey.TikTok]: {
    key: ColumnKey.TikTok,
    title: 'TikTok',
    dataIndex: '_raw',
    width: 80,
    maxWidth: 150,
  },
  [ColumnKey.Owners]: {
    key: ColumnKey.Owners,
    dataIndex: 'member',
    title: 'Owners',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.DaysInStage]: {
    key: ColumnKey.DaysInStage,
    dataIndex: ColumnKey.DaysInStage,
    title: 'Days in Stage',
    width: 100,
    maxWidth: 110,
  },
  [ColumnKey.DateAdded]: {
    key: ColumnKey.DateAdded,
    dataIndex: ColumnKey.DateAdded,
    title: 'Date Added',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.DateCompleted]: {
    key: ColumnKey.DateCompleted,
    dataIndex: ColumnKey.DateCompleted,
    title: 'Date Completed',
    width: 120,
    maxWidth: 150,
  },
  [ColumnKey.PendingContracts]: {
    key: ColumnKey.PendingContracts,
    dataIndex: ColumnKey.PendingContracts,
    title: 'Pending Contracts',
    width: 120,
    maxWidth: 150,
  },
  [ColumnKey.PaypalAddress]: {
    key: ColumnKey.PaypalAddress,
    dataIndex: ColumnKey.PaypalAddress,
    title: 'PayPal Address',
    width: 120,
    maxWidth: 250,
  },
  [ColumnKey.LastPaymentSent]: {
    key: ColumnKey.LastPaymentSent,
    dataIndex: ColumnKey.LastPaymentSent,
    title: 'Last Payment Sent',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.Message]: {
    key: ColumnKey.Message,
    dataIndex: ColumnKey.Message,
    title: 'Error',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.Deliverables]: {
    key: ColumnKey.Deliverables,
    dataIndex: ColumnKey.Deliverables,
    title: 'Deliverables',
    width: 80,
    maxWidth: 120,
  },
  [ColumnKey.Price]: {
    key: ColumnKey.Price,
    dataIndex: ColumnKey.Price,
    title: 'Price',
    width: 80,
    maxWidth: 120,
  },
  [ColumnKey.NextDeadline]: {
    key: ColumnKey.NextDeadline,
    dataIndex: ColumnKey.NextDeadline,
    title: 'Next Deadline',
    width: 120,
    minWidth: 120,
    maxWidth: 150,
  },
  [ColumnKey.OpenDeliverables]: {
    key: ColumnKey.OpenDeliverables,
    dataIndex: ColumnKey.OpenDeliverables,
    title: 'Open Deliverables',
    width: 120,
    minWidth: 120,
    maxWidth: 150,
  },
  [ColumnKey.CompletedDeliverables]: {
    key: ColumnKey.CompletedDeliverables,
    dataIndex: ColumnKey.CompletedDeliverables,
    title: 'Completed Deliverables',
    width: 120,
    maxWidth: 150,
  },
  [ColumnKey.Address]: {
    key: ColumnKey.Address,
    dataIndex: 'member',
    title: 'Address',
    width: 120,
    maxWidth: 250,
  },
  [ColumnKey.Address1]: {
    key: ColumnKey.Address1,
    dataIndex: 'member',
    title: 'Address 1',
    width: 120,
    maxWidth: 250,
  },
  [ColumnKey.Address2]: {
    key: ColumnKey.Address2,
    dataIndex: 'member',
    title: 'Address 2',
    width: 120,
    maxWidth: 250,
  },
  [ColumnKey.City]: {
    key: ColumnKey.City,
    dataIndex: 'member',
    title: 'City',
    width: 100,
    maxWidth: 120,
  },
  [ColumnKey.State]: {
    key: ColumnKey.State,
    dataIndex: 'member',
    title: 'State',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.PostalCode]: {
    key: ColumnKey.PostalCode,
    dataIndex: 'member',
    title: 'Postal Code',
    width: 80,
    maxWidth: 150,
  },
  [ColumnKey.Country]: {
    key: ColumnKey.Country,
    dataIndex: 'member',
    title: 'Country',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.OrderStatus]: {
    key: ColumnKey.OrderStatus,
    dataIndex: ColumnKey.OrderStatus,
    title: 'Order Status',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.TotalShippedProducts]: {
    key: ColumnKey.TotalShippedProducts,
    dataIndex: ColumnKey.Orders,
    title: 'Total Shipped Products',
    width: 200,
    maxWidth: 200,
  },
  [ColumnKey.TotalShippedMarketValue]: {
    key: ColumnKey.TotalShippedMarketValue,
    dataIndex: ColumnKey.Orders,
    title: 'Total Shipped Market Value',
    width: 200,
    maxWidth: 200,
  },
  [ColumnKey.TotalOrderedProductsInPending]: {
    key: ColumnKey.TotalOrderedProductsInPending,
    dataIndex: ColumnKey.Orders,
    title: 'Total Ordered Products',
    width: 200,
    maxWidth: 200,
  },
  [ColumnKey.TotalOrderedMarketValueInPending]: {
    key: ColumnKey.TotalOrderedMarketValueInPending,
    dataIndex: ColumnKey.Orders,
    title: 'Total Ordered Market Value',
    width: 200,
    maxWidth: 200,
  },
  [ColumnKey.OrderNumber]: {
    key: ColumnKey.OrderNumber,
    dataIndex: ColumnKey.Orders,
    title: 'Order Number',
    width: 150,
    maxWidth: 200,
  },
  [ColumnKey.OrderDate]: {
    key: ColumnKey.OrderDate,
    dataIndex: ColumnKey.Orders,
    title: 'Order Date',
    width: 150,
    maxWidth: 200,
  },
  [ColumnKey.OrderFulfillmentStatus]: {
    key: ColumnKey.OrderFulfillmentStatus,
    dataIndex: ColumnKey.Orders,
    title: 'Fulfillment Status',
    width: 150,
    maxWidth: 200,
  },
  [ColumnKey.OrderShipmentStatus]: {
    key: ColumnKey.OrderShipmentStatus,
    dataIndex: ColumnKey.Orders,
    title: 'Shipment Status',
    width: 150,
    maxWidth: 200,
  },
  [ColumnKey.OrderTrackingNumber]: {
    key: ColumnKey.OrderTrackingNumber,
    dataIndex: ColumnKey.Orders,
    title: 'Tracking Number',
    width: 150,
    maxWidth: 200,
  },
  [ColumnKey.Quantity]: {
    key: ColumnKey.Quantity,
    dataIndex: ColumnKey.Quantity,
    title: 'Quantity',
    width: 80,
    maxWidth: 120,
  },
  [ColumnKey.Products]: {
    key: ColumnKey.Products,
    dataIndex: ColumnKey.Products,
    title: 'Product Selected',
    width: 150,
    maxWidth: 300,
  },
  [ColumnKey.ShipmentStatus]: {
    key: ColumnKey.ShipmentStatus,
    dataIndex: ColumnKey.ShipmentStatus,
    title: 'Shipment Status',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.TrackingNumber]: {
    key: ColumnKey.TrackingNumber,
    dataIndex: ColumnKey.TrackingNumber,
    title: 'Tracking Number',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.PromoCode]: {
    key: ColumnKey.PromoCode,
    dataIndex: ColumnKey.PromoCode,
    title: 'Promo Code',
    width: 100,
    maxWidth: 150,
  },
  [ColumnKey.PromoCodeStatus]: {
    key: ColumnKey.PromoCodeStatus,
    dataIndex: ColumnKey.PromoCodeStatus,
    title: 'Status',
    width: 100,
    maxWidth: 120,
  },
  [ColumnKey.AffiliateLink]: {
    key: ColumnKey.AffiliateLink,
    dataIndex: ColumnKey.AffiliateLink,
    title: 'Link',
    width: 150,
    maxWidth: 250,
  },
  [ColumnKey.ShippingAddress]: {
    key: ColumnKey.ShippingAddress,
    dataIndex: ColumnKey.ShippingAddress,
    title: 'Shipping Address',
    width: 120,
    maxWidth: 250,
  },
  [ColumnKey.ShippingCountry]: {
    key: ColumnKey.ShippingCountry,
    dataIndex: ColumnKey.ShippingCountry,
    title: 'Shipping Country',
    width: 120,
    maxWidth: 250,
  },
};
