import * as React from 'react';
import {
   Form, AutoComplete,
} from '@revfluence/fresh';
import styles from '@affiliates/components/OfferForm/OfferForm.scss';
import { useState } from 'react';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { UTMFieldsProps } from '../../../types';

interface IProps extends UTMFieldsProps<'advanceUrlSettingType'> {
  events: {
    onBlur: () => void;
    onFocus: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}
const placeholder = 'Enter a value or select a dynamic parameter';
const dynamicUtmOption = {
  creatorHandle: '{creator_name}',
  instaHandle: '{creator_ig_username}',
  projectName: '{project_name}',
  linkOfferName: '{link_offer_name}',
};
// eslint-disable-next-line react/display-name
export const UtmFields: React.FC<Readonly<IProps>> = React.memo(({
  label,
  keyName,
  events,
  required,
}) => {
  const handleInputChange = (newValue) => {
    setCustomText(newValue);
  };
  const [customText, setCustomText] = useState('');
  const getRequiredRule = (inputName) => ({ required: true, message: `Please Enter ${inputName}` });

  const getHashValidationRule = () => ({
    validator: (_, value) => {
      if (value && (value.includes('#') || value.includes(' ') || value.includes('='))) {
        return Promise.reject(new Error('Value cannot contain the \'#\', \'=\' or space character'));
      }
      return Promise.resolve();
    },
  });

  const dataSource = ['Dynamic Parameter', ...Object.values(dynamicUtmOption)];

  const disabledOptionStyles = {
    color: 'var(--neutral-color-palette-gray-9, #1F1F21)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
  };

  return (

    <div>
      <div className={styles.inputBox}>
        <Form.Item label={label} name={keyName} rules={required ? [getRequiredRule(keyName), getHashValidationRule()] : [getHashValidationRule()]}>
          <AutoComplete
            data-testid={testIds.utmSource}
            value={customText}
            onChange={handleInputChange}
            placeholder={placeholder}
            size="large"
            {...events}
          >
            {dataSource.map((option, index) => (
              <AutoComplete.Option key={index} value={option} disabled={option == 'Dynamic Parameter'} style={option === 'Dynamic Parameter' ? disabledOptionStyles : null}>
                {option}
              </AutoComplete.Option>
                ))}
          </AutoComplete>
        </Form.Item>
      </div>
    </div>

  );
});
