import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { WidgetContainer, DataTable } from '@frontend/app/composite-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { cn } from '@/shadcn/lib/utils';

const { useState, useEffect } = React;
type RowItem = {
  id: string;
  name: string;
  creatorsCount: number;
  totalSpend: number;
  averageSpend: number;
};

const mockData: RowItem[] = [
  {
    id: '1',
    name: 'Nano Creator',
    creatorsCount: 12,
    totalSpend: 25000,
    averageSpend: 2083.33,
  },
  {
    id: '2',
    name: 'Beta Creator',
    creatorsCount: 8,
    totalSpend: 18000,
    averageSpend: 2250.0,
  },
  {
    id: '3',
    name: 'Gamma Creator',
    creatorsCount: 15,
    totalSpend: 32000,
    averageSpend: 2133.33,
  },
  {
    id: '4',
    name: 'Delta Creator',
    creatorsCount: 10,
    totalSpend: 27000,
    averageSpend: 2700.0,
  },
  {
    id: '5',
    name: 'Epsilon Creator',
    creatorsCount: 6,
    totalSpend: 15000,
    averageSpend: 2500.0,
  },
  {
    id: '6',
    name: 'Zeta Creator',
    creatorsCount: 14,
    totalSpend: 31000,
    averageSpend: 2214.29,
  },
];
interface SpendByCreatorSegmentWidgetProps {
  className?: string;
}

export default function SpendByCreatorSegmentWidget({ className }: SpendByCreatorSegmentWidgetProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RowItem[]>([]);
  useEffect(() => {
    setTimeout(() => {
      setData(mockData);
      setLoading(false);
    }, 2000);
  }, []);

  const columns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'name',
      header: () => <div className="font-semibold text-[#1F1F21]">Name</div>,
    },
    {
      accessorKey: 'creatorsCount',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Number of Creators</div>,
      cell: ({ row }) => {
        const creatorsCount = parseFloat(row.getValue('creatorsCount'));
        return <div className="text-right font-medium">{creatorsCount}</div>;
      },
    },
    {
      accessorKey: 'totalSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Total Spend</div>,
      cell: ({ row }) => {
        const totalSpend = parseFloat(row.getValue('totalSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalSpend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'averageSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21]">Avg Spend</div>,
      cell: ({ row }) => {
        const averageSpend = parseFloat(row.getValue('averageSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(averageSpend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];
  if (loading) {
    return (
      <WidgetContainer
        widgetTitle="Spend by Creator Segment"
        className={cn(className, '')}
        bgColor="bg-white"
        textColor="text-gray-700"
      >
        <Skeleton className="h-6 w-full mb-4" />
        <Skeleton className="h-6 w-full mb-4" />
        <Skeleton className="h-6 w-full mb-4" />
      </WidgetContainer>
    );
  }
  return (
    <WidgetContainer
      widgetTitle="Spend by Creator Segment"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      <DataTable columns={columns} data={data} />
    </WidgetContainer>
  );
}
