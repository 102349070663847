import React, { useCallback, ChangeEvent, useEffect } from 'react';
import {
 Row, Form, Input, Button, Typography, Card,
} from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { LoadSpinner } from '@components';
import { Select, Popover, Progress } from '@revfluence/fresh';
import FiscalYearBudgetBreakdown from '@frontend/app/components/FiscalYearBudgetBreakdown/FiscalYearBudgetBreakdown';
import { BudgetGranularity, FiscalYearBudgetBreakdown as FiscalYearBudgetBreakdownType } from '@frontend/app/types/Budget';
import { useGetBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccountDistribution';
import { GetBudgetAccountDistribution_budgetAccountDistribution } from '@frontend/app/queries/types/GetBudgetAccountDistribution';
import { GetBudgetFiscalSettingsQuery_budgetSettingsResponse } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';
import { v4 as uuidv4 } from 'uuid';
import { InfoCircleOutlined } from '@ant-design/icons';
import { BudgetAccountFormField } from './constants/budgetAccountForm.fields';
import BudgetInfo from './components/budgetInfo';

const { Text } = Typography;
const { Option } = Select;

import styles from './budgetAccountForm.scss';

const ChartsSVG = require('@frontend/app/assets/svgs/charts.svg');

const { Title } = Typography;

export const mapToFormValues = (data, defaultValues?: FormValues): FormValues => ({
  budgetId: data?.budgetId,
  budgetName: data?.budgetName || defaultValues?.budgetName || '',
  budgetDescription: data?.budgetDescription || defaultValues?.budgetDescription || '',
  parentBudgetId: data?.parentBudgetId,
  fiscalYearsBudget: (data?.fiscalYearsBudget || defaultValues?.fiscalYearsBudget || [])?.map((budget) => ({
    ...{
      ...budget,
      quarterBudgets: !isEmpty(budget?.quarterBudgets) ? budget?.quarterBudgets.slice(0, 4) : [0, 0, 0, 0],
      monthBudgets: !isEmpty(budget?.monthlyBudgets) ? budget?.quarterBudgets : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    id: uuidv4(),
  })),
});

export interface FormValues {
  budgetId?: number;
  budgetName: string;
  budgetDescription: string;
  parentBudgetId?: number;
  fiscalYearsBudget: FiscalYearBudgetBreakdownType[];
}

interface IProps {
  values?: FormValues;
  setValues?: React.Dispatch<React.SetStateAction<FormValues>>;
  budgetSettingsResponse?: GetBudgetFiscalSettingsQuery_budgetSettingsResponse;
  budgetNameDisabled?: boolean;
  isMasterBudget?: boolean;
  title?: string;
  description?: string;
  showBudgetToLink?: boolean;
  saveButtonText?: string;
  loading?: boolean;
  saving?: boolean;
  onSave: () => void;
  disabledBudgetToLinkIfPresent?: boolean;
  isSettingsPage?: boolean;
}

const renderParentBudgetDetails = (parentBudget) => {
  if (!parentBudget) return null;
  const { budgetName } = parentBudget;
  const availableBudget = parentBudget.fiscalYearsBudget.reduce((acc, year) => acc + year.availableAmount, 0);
  const totalBudget = parentBudget.fiscalYearsBudget.reduce((acc, year) => acc + year.totalAmount, 0);
  return (
    <div className={styles.parentBudgetDetailsPopover}>
      <div className={styles.parentBudgetPopoverTitle}>
        <Title level={5} style={{ margin: 0 }}>
          {budgetName}
        </Title>
      </div>
      <div className={styles.parentBudgetAmountsContainer}>
        <Text className={styles.parentBudgetAmount}>
          $
          {totalBudget.toLocaleString()}
        </Text>
        <Text style={{ fontSize: 16, color: '#1f1f21', fontWeight: 600 }}>
          $
          {availableBudget.toLocaleString()}
        </Text>
      </div>
      <div className={styles.parentBudgetAmountLabelContainer}>
        <Text>Total Budget</Text>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.availableBudgetIndicator} />
          <Text style={{ marginLeft: 6 }}>Available</Text>
        </div>
      </div>
      <Progress percent={(availableBudget / totalBudget) * 100} showInfo={false} strokeColor="#5DB884" />
    </div>
  );
};

const BudgetAccountForm: React.FunctionComponent<IProps> = ({
  values,
  setValues,
  budgetSettingsResponse,
  budgetNameDisabled = false,
  title = '',
  description = '',
  showBudgetToLink = true,
  saveButtonText = 'Save',
  isMasterBudget = false,
  loading = false,
  saving = false,
  onSave,
  disabledBudgetToLinkIfPresent = true,
  isSettingsPage,
}: IProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const onFinish = useCallback(
    (_) => {
      form.setFieldsValue(values);
      form
        .validateFields()
        .then(() => {
          onSave();
        })
        .catch((error) => {
          console.error('Validation Failed:', error);
        });
    },
    [onSave, form, values],
  );

  const {
    budgetAccountDistribution,
    loading: budgetsLoading,
  }: {
    budgetAccountDistribution: GetBudgetAccountDistribution_budgetAccountDistribution[];
    loading: boolean;
  } = useGetBudgetAccountDistribution({});

  const onChangeHandlerMap: {
    [key: string]: (
      e:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLTextAreaElement>
        | string
        | number
        | FiscalYearBudgetBreakdownType[],
    ) => void;
  } = {
    [BudgetAccountFormField.BUDGET_NAME]: (e: ChangeEvent<HTMLInputElement>) =>
      setValues((prev) => ({ ...prev, [BudgetAccountFormField.BUDGET_NAME]: e.target.value })),
    [BudgetAccountFormField.BUDGET_DESCRIPTION]: (e: ChangeEvent<HTMLTextAreaElement>) =>
      setValues((prev) => ({ ...prev, [BudgetAccountFormField.BUDGET_DESCRIPTION]: e.target.value })),
    [BudgetAccountFormField.PARENT_BUDGET]: (e: number) =>
      setValues((prev) => ({
 ...prev,
[BudgetAccountFormField.PARENT_BUDGET]: e,
[BudgetAccountFormField.FISCAL_YEAR_BREAKDOWN]: [{
        id: uuidv4(),
        fiscalYear: budgetSettingsResponse?.fiscalYears?.find((fiscalYear) => fiscalYear?.isCurrentYear)?.year,
        granularity: BudgetGranularity.YEARLY,
        isNew: true,
        totalAmount: 0,
        availableAmount: 0,
        quarterBudgets: [0, 0, 0, 0],
        monthBudgets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      }],
})),
    [BudgetAccountFormField.FISCAL_YEAR_BREAKDOWN]: (e: FiscalYearBudgetBreakdownType[]) =>
      setValues(
        (prev) =>
          ({
            ...prev,
            fiscalYearsBudget: e,
          } as FormValues),
      ),
  };

  const handleCancel = useCallback(() => {
    history.push(`${isSettingsPage ? '/settings' : ''}/budget/dashboard`);
  }, [history, isSettingsPage]);

  const masterBudget = budgetAccountDistribution?.find((budget) => budget.budgetName === 'Master Budget');

  const parentBudgetOptions = budgetAccountDistribution
    ?.filter(
      (budget) =>
        budget?.budgetId !== values?.budgetId
        && (budget?.parentBudgetId == null || budget?.parentBudgetId == masterBudget?.budgetId),
    )
    ?.map((budget) => ({
      label: budget?.budgetName,
      value: budget?.budgetId,
    }));

  useEffect(() => {
    if (!values?.budgetId && !values?.parentBudgetId && budgetAccountDistribution && masterBudget?.budgetId) {
      setValues((prev) => ({ ...prev, parentBudgetId: masterBudget?.budgetId }));
    }
    if (!values?.budgetId) {
      const masterBudgetGranularity = masterBudget?.fiscalYearsBudget?.find((fy) => fy.fiscalYear === values?.parentBudgetId)?.granularity;
      setValues((prev) => ({ ...prev, fiscalYearsBudget: prev?.fiscalYearsBudget?.map((fiscalYear) => ({ ...fiscalYear, granularity: masterBudgetGranularity || BudgetGranularity.YEARLY })) }));
    }
  /* eslint-disable */
  }, [budgetAccountDistribution, setValues]);

  const isQuarterlyAmountExceeding = values?.fiscalYearsBudget?.some((fiscalYearBudget) => {
    const totalQuarterlyAmount = fiscalYearBudget?.quarterBudgets?.reduce((acc, amount) => acc + amount, 0);
    return totalQuarterlyAmount > fiscalYearBudget?.totalAmount;
  });

  const shouldDisableSave = isEmpty(values?.fiscalYearsBudget) || isQuarterlyAmountExceeding;

  if (loading || budgetsLoading) return <LoadSpinner className={styles.loadSpinner} />;

  return (
    <Row wrap={false} className={styles.budgetAccountContainer}>
      <div className={styles.formContainer}>
        <Form form={form} name="budget_form" layout="vertical" initialValues={values}>
          <Card style={{ marginBottom: '24px' }}>
            <Title level={4}>{title}</Title>
            <p>{description}</p>

            <Form.Item
              label="Budget Name"
              name={BudgetAccountFormField.BUDGET_NAME}
              rules={[{ required: true, message: 'Please Enter Budget Name' }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Input
                placeholder="Enter Budget Name"
                disabled={budgetNameDisabled}
                onChange={onChangeHandlerMap[BudgetAccountFormField.BUDGET_NAME]}
                value={values?.budgetName}
              />
            </Form.Item>

            <Form.Item label="Budget Description (Optional)" name={BudgetAccountFormField.BUDGET_DESCRIPTION}>
              <Input.TextArea
                placeholder="Briefly describe your budget"
                onChange={onChangeHandlerMap[BudgetAccountFormField.BUDGET_DESCRIPTION]}
                value={values?.budgetDescription}
              />
            </Form.Item>
          </Card>
          {showBudgetToLink && (
            <Card style={{ marginBottom: '24px' }} className={styles.budgetToLinkContainer}>
              <Form.Item
                name={BudgetAccountFormField.PARENT_BUDGET}
                required
                rules={[{ required: true, message: 'Please Select a Parent Budget' }]}
                label="Select the budget source"
                validateTrigger={['onChange', 'onBlur']}
              >
                <Select
                  options={parentBudgetOptions}
                  disabled={disabledBudgetToLinkIfPresent && !!values?.parentBudgetId}
                  value={values?.parentBudgetId}
                  placeholder="Select a Budget"
                  onChange={onChangeHandlerMap[BudgetAccountFormField.PARENT_BUDGET]}
                  className={styles.budgetToLink}
                >
                  {parentBudgetOptions?.map((option) => (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                <Popover
                  content={renderParentBudgetDetails(
                    budgetAccountDistribution?.find((budget) => budget.budgetId == values?.parentBudgetId),
                  )}
                  trigger="hover"
                >
                  <img src={ChartsSVG} alt="charts" />
                </Popover>
              </Form.Item>
              <div className={styles.budgetToLinkInfo}>
                <InfoCircleOutlined />
                <span>
                  <Text>
                    The budget period, fiscal year, and amount allocated for the Campaign Budget are set based on the
                    main budget it's linked to.
                    {' '}
                  </Text>
                </span>
              </div>
            </Card>
          )}
          <Form.Item name={BudgetAccountFormField.FISCAL_YEAR_BREAKDOWN}>
            <FiscalYearBudgetBreakdown
              parentBudgetId={values?.parentBudgetId}
              budgetAccountDistribution={budgetAccountDistribution}
              fiscalYearsBudget={values?.fiscalYearsBudget}
              setFiscalYearsBudget={onChangeHandlerMap[BudgetAccountFormField.FISCAL_YEAR_BREAKDOWN]}
            />
          </Form.Item>
        </Form>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ height: '40px' }}
            loading={saving}
            onClick={onFinish}
            disabled={shouldDisableSave}
          >
            {saveButtonText}
          </Button>
          <Button
            type="default"
            onClick={handleCancel}
            style={{ borderColor: 'transparent', background: 'transparent' }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className={styles.rightSectionContainer}>
        <BudgetInfo values={values} isMasterBudget={isMasterBudget} />
      </div>
    </Row>
  );
};

export default BudgetAccountForm;
