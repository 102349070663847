import React from 'react';
import { WidgetContainer, MainMetric, TrendIcon } from '@frontend/app/composite-components';
import { Bar, BarChart, XAxis } from 'recharts';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';

const chartData = [
  { source: 'Sales Tracking', percent: 50, fill: 'var(--color-salesTracking)' },
  { source: 'Terms', percent: 20, fill: 'var(--color-terms)' },
  { source: 'Others', percent: 30, fill: 'var(--color-others)' },
];

const chartConfig = {
  salesTracking: {
    label: 'Sales Tracking',
    color: 'hsl(var(--chart-1))',
  },
  terms: {
    label: 'Terms',
    color: 'hsl(var(--chart-2))',
  },
  others: {
    label: 'Others',
    color: 'hsl(var(--chart-3))',
  },
} as ChartConfig;

interface SourceLevelSpendWidgetProps {
  className?: string;
}

export default function SourceLevelSpendWidget({ className }: SourceLevelSpendWidgetProps) {
  return (
    <WidgetContainer widgetTitle="Total Spend" className={`${className}`} bgColor="bg-white" textColor="text-gray-700">
      <div className="flex flex-col">
        <MainMetric
          className="flex-1"
          value={9100000001}
          size="medium"
          metricType="currency"
          subHeading="of Total Budget"
          icon={<TrendIcon trend="up" value="2%" />}
        />
        <ChartContainer config={chartConfig} className="mx-auto h-[300px] w-[fullx]">
          <BarChart data={chartData}>
            <XAxis dataKey="source" tickLine={false} tickMargin={10} axisLine={false} />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Bar dataKey="percent" strokeWidth={3} radius={8} />
            <ChartLegend content={<ChartLegendContent nameKey="source" />} className="flex-wrap gap-2" />
          </BarChart>
        </ChartContainer>
      </div>
    </WidgetContainer>
  );
}
