import React from 'react';
import classNames from 'classnames';

type SectionHeaderProps = {
  title: string;
  description: string;
  icon?: React.ReactNode;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, description, icon }) => (
  <div className="py-4">
    <div className="flex items-center gap-2">
      {icon && <div>{icon}</div>}
      <h2 className={classNames('text-2xl font-semibold m-0')}>
        {title}
      </h2>
    </div>
    <p className="text-gray-600 mt-1">
      {description}
    </p>
  </div>
);

export default SectionHeader;
