import React, {
 useCallback, useEffect, useMemo, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { ProgramStatusType } from '@services/communities/types';
import {
  HouseChimneyIcon,
  SquareKanbanIcon,
  SidebarIcon,
  MagnifyingGlassIcon,
  UserGroupIcon,
  UserPlusIcon,
  InboxIcon,
  ChartColumnIcon,
  AtIcon,
  PlusIcon,
  ChartBarIcon,
  CartShoppingIcon,
  SignalStreamIcon,
  ImageIcon,
  CreditCardIcon,
  GiftIcon,
  FileIcon,
  CommentDollarIcon,
  SackDollarIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import {
  CONTENT_APP_ID,
  FULFILLMENT_APP_ID,
  PAYMENT_APP_ID,
  SALES_APP_ID,
  SOCIAL_DISCOVERY_APP_ID,
  SOCIAL_POST_APP_ID,
  GMAIL_APP_ID,
  OUTLOOK_APP_ID,
  CONTRACT_APP_ID,
  SHOPIFY_APP_ID,
} from '@/app/constants/applicationIds';
import { Button, buttonVariants } from '@/shadcn/components/ui/button';
import {
  Layout,
  LeftSidebar,
  LeftSidebarContent,
  Navbar,
  SecondaryNav,
  MainContent,
} from '@/shadcn/layouts/double-sidebar';
import {
 Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@/shadcn/components/ui/tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuTrigger,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from '@/shadcn/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar';
import { Muted } from '@/shadcn/components/typography/muted';
import { Small } from '@/shadcn/components/typography/small';
import { ExtraSmall } from '@/shadcn/components/typography/extra-small';
import { H4 } from '@/shadcn/components/typography/h4';
import { useProjectsApp } from '../Projects/hooks';
import {
  useClientFeatureEnabled,
  useCommunitiesQuery,
  useGetCurrentClient,
  useGetInstalledApplicationIds,
  useGetProfile,
} from '@/app/hooks';
import { ClientFeature } from '@/app/constants';
import { ProjectByIdQuery_project } from '@/app/queries/types/ProjectByIdQuery';
import { GetCommunitiesQuery_communities } from '@/app/queries/types/GetCommunitiesQuery';
import { MemberSearch } from '@/app/components/MemberSearch/MemberSearchShadCN';
import { useAuth } from '@/context/authContext';

function useProjects() {
  const { projects } = useProjectsApp();
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const projectsToRender = useMemo(() => {
    const filteredProjects = projects.filter(
      (project) => !isArchiveProjectEnabled || (isArchiveProjectEnabled && project.status === ProjectStatus.Active),
    );
    filteredProjects.sort((a, b) => a.title.localeCompare(b.title));
    return filteredProjects;
  }, [projects, isArchiveProjectEnabled]);
  return projectsToRender;
}

const useGroups = () => {
  const { data: { communities: groups = [] } = {} } = useCommunitiesQuery();
  const groupsToRender = useMemo(() => {
    const groupToSort = [...groups];
    groupToSort && groupToSort.sort((a, b) => a.title.localeCompare(b.title));
    return groupToSort;
  }, [groups]);
  return groupsToRender;
};

function useOutsideAlerter({
  targetRef,
  callback,
  excludeRefs,
}: {
  targetRef: React.RefObject<HTMLElement>;
  excludeRefs?: React.RefObject<HTMLElement>[];
  callback: () => void;
}) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        targetRef.current
        && !targetRef.current.contains(event.target)
        && (!excludeRefs || !excludeRefs.some((excludeRef) => excludeRef.current?.contains(event.target)))
      ) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef, excludeRefs, callback]);
}

type SecondaryNavModes = 'recruit' | 'reporting' | 'projects' | 'members' | undefined;

export type SecondaryNavProps = {
  projects?: ProjectByIdQuery_project[];
  onItemSelected?: () => void;
  groups?: GetCommunitiesQuery_communities[];
};

const MenuHeaderLayout = ({ children }) => <div className="flex items-center">{children}</div>;
const MenuHeader = ({ children }) => (
  <H4 className="tracking-tight text-sm font-medium text-muted-foreground mb-0">{children}</H4>
);

const MenuList = ({ children }) => <ul className="grid gap-2">{children}</ul>;

const MenuListItem = ({ children }) => <li>{children}</li>;

const MenuLink = React.forwardRef<React.ElementRef<typeof Link>, React.ComponentPropsWithoutRef<typeof Link>>(
  ({ children, ...props }, ref) => (
    <Link
      ref={ref}
      className={cn(
        buttonVariants({ variant: 'ghost', size: 'sm' }),
        'flex items-center gap-2 overflow-x-hidden text-foreground hover:text-foreground group justify-start h-12 ',
      )}
      {...props}
    >
      {children}
    </Link>
  ),
);
MenuLink.displayName = 'MenuLink';

const MenuItemContent = ({ children }) => <div className="grid gap-1 overflow-y-hidden truncate">{children}</div>;
const MenuTitle = ({ children }) => <p className="text-sm font-medium m-0 truncate">{children}</p>;
const MenuCount = ({ children }) => (
  <p className="text-sm text-muted-foreground group-hover:text-foreground m-0 ml-auto">{children}</p>
);

const MenuIconWrapper = ({ children }) => <div className="flex h-9 w-9">{children}</div>;
const MenuLayout = ({ children }) => <div className="grid gap-6">{children}</div>;

const ProjectStatus = {
  Active: ProgramStatusType.ACTIVE,
  Archived: ProgramStatusType.ARCHIVED,
  Deleted: ProgramStatusType.DELETED,
};

const UserMenu = ({
 user, currentClient, profile, pfaV2Enabled, onSwitchClient, installedApps, logout,
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="outline" size="icon" className="overflow-hidden rounded-full">
        <Avatar>
          <AvatarImage src={user.picture} alt={user.name} className="object-cover" />
          <AvatarFallback>
            {user?.name
              .split(' ')
              .map((n) => n[0])
              .join()}
          </AvatarFallback>
        </Avatar>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent side="right" align="end">
      <DropdownMenuLabel>
        <Small>{currentClient?.name}</Small>
        <Muted>{profile.email}</Muted>
      </DropdownMenuLabel>
      {profile?.clients?.length > 1 && (
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Switch Accounts</DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              {profile.clients.map((client) => (
                <DropdownMenuItem
                  key={client.id}
                  onSelect={() => {
                    onSwitchClient(client);
                  }}
                >
                  {client.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem asChild>
        <Link className="cursor-pointer text-inherit" to="/settings/account">
          My Account
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <Link className="cursor-pointer text-inherit" to={`/settings/${PAYMENT_APP_ID}`}>
          Workspace Settings
        </Link>
      </DropdownMenuItem>
      {pfaV2Enabled && installedApps[SHOPIFY_APP_ID] && (
        <DropdownMenuItem asChild>
          <Link className="cursor-pointer text-inherit" to="/settings/products">
            Product Fulfillment
          </Link>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem asChild>
        <Link
          className="cursor-pointer text-inherit"
          to={installedApps[GMAIL_APP_ID] ? `/settings/${GMAIL_APP_ID}` : `/settings/${OUTLOOK_APP_ID}`}
        >
          Integrations
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <Link className="cursor-pointer text-inherit" to="/settings/conversationPrivacy">
          Organization Settings
        </Link>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        onSelect={() => {
          logout();
        }}
      >
        Log Out
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);

export const SecondaryProjectsNav = ({ onItemSelected, projects }: SecondaryNavProps) => (
  <MenuLayout>
    <MenuHeaderLayout>
      <MenuHeader>Projects</MenuHeader>
      <Button
        size="xs"
        variant="outline"
        className="ml-auto text-muted-foreground  hover:text-foreground group gap-1.5"
        onClick={onItemSelected}
        asChild
      >
        <Link to="/projects/new/templates">
          <PlusIcon className="h-3 w-3 text-muted-foreground  group-hover:text-foreground" />
          <span className="text-muted-foreground  group-hover:text-foreground">New</span>
        </Link>
      </Button>
    </MenuHeaderLayout>
    <TooltipProvider>
      <MenuList>
        {projects.map((project) => (
          <MenuListItem key={project.id}>
            <Tooltip>
              <TooltipTrigger asChild>
                <MenuLink onClick={onItemSelected} to={`/projects/${project.id}`}>
                  <Avatar className="h-9 w-9 flex rounded-md">
                    <AvatarImage src={project?.splashImageUrl} alt="Avatar" className="object-cover" />
                    <AvatarFallback className="rounded-md">{project?.title?.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <MenuItemContent>
                    <MenuTitle>{project?.title}</MenuTitle>
                  </MenuItemContent>
                </MenuLink>
              </TooltipTrigger>
              <TooltipContent className="z-[190]">{project?.title}</TooltipContent>
            </Tooltip>
          </MenuListItem>
        ))}
      </MenuList>
    </TooltipProvider>
  </MenuLayout>
);

export const SecondaryMembersNav = ({ onItemSelected, groups }: SecondaryNavProps) => (
  <MenuLayout>
    <MenuHeaderLayout>
      <MenuHeader>Members</MenuHeader>
    </MenuHeaderLayout>
    <MenuList>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to="/member_table?&switchSourcing=true">
          <MenuIconWrapper>
            <UserGroupIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>All Contacts</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
    </MenuList>
    <MenuHeaderLayout>
      <MenuHeader>Groups</MenuHeader>
      <Button
        size="xs"
        variant="outline"
        className="ml-auto text-muted-foreground  hover:text-foreground group gap-1.5"
        onClick={onItemSelected}
        asChild
      >
        <Link to="/communities/new">
          <PlusIcon className="h-3 w-3 text-muted-foreground  group-hover:text-foreground" />
          <span className="text-muted-foreground  group-hover:text-foreground">New</span>
        </Link>
      </Button>
    </MenuHeaderLayout>
    <TooltipProvider>
      <MenuList>
        {groups.map((group) => (
          <MenuListItem key={group.id}>
            <Tooltip>
              <TooltipTrigger asChild>
                <MenuLink onClick={onItemSelected} to={`/member_table?communityId=${group.id}`}>
                  <Avatar className="h-9 w-9 flex">
                    <AvatarImage src={group?.splashImageUrl} alt="Avatar" className="object-cover" />
                    <AvatarFallback className="rounded-md ">{group?.title?.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <MenuItemContent>
                    <MenuTitle>{group?.title}</MenuTitle>
                  </MenuItemContent>
                  <MenuCount>{group?.memberCount}</MenuCount>
                </MenuLink>
              </TooltipTrigger>
              <TooltipContent>{group?.title}</TooltipContent>
            </Tooltip>
          </MenuListItem>
        ))}
      </MenuList>
    </TooltipProvider>
  </MenuLayout>
);

export const SecondaryReportingNav = ({ onItemSelected }: SecondaryNavProps) => (
  <MenuLayout>
    <MenuHeaderLayout>
      <MenuHeader>Reporting</MenuHeader>
    </MenuHeaderLayout>
    <MenuList>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to="/impact_dashboard">
          <MenuIconWrapper>
            <ChartBarIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Impact Dashboard</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${SALES_APP_ID}`}>
          <MenuIconWrapper>
            <CartShoppingIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Sales Tracking</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${SOCIAL_POST_APP_ID}`}>
          <MenuIconWrapper>
            <SignalStreamIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Social Analytics</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${CONTENT_APP_ID}`}>
          <MenuIconWrapper>
            <ImageIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Content</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
    </MenuList>
    <MenuHeaderLayout>
      <MenuHeader>Finance</MenuHeader>
    </MenuHeaderLayout>
    <MenuList>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${PAYMENT_APP_ID}`}>
          <MenuIconWrapper>
            <CreditCardIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Payments</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to="/budget/dashboard">
          <MenuIconWrapper>
            <SackDollarIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Budget Dashboard</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
    </MenuList>
    <MenuHeaderLayout>
      <MenuHeader>Partnerships</MenuHeader>
    </MenuHeaderLayout>
    <MenuList>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${FULFILLMENT_APP_ID}`}>
          <MenuIconWrapper>
            <GiftIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Product Fulfillment</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${CONTRACT_APP_ID}`}>
          <MenuIconWrapper>
            <FileIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Contracts</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to="/influencer_whitelist">
          <MenuIconWrapper>
            <CommentDollarIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Ads Permissions</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
    </MenuList>
  </MenuLayout>
);

export const SecondaryRecruitNav = ({ onItemSelected }: SecondaryNavProps) => (
  <MenuLayout>
    <MenuHeaderLayout>
      <MenuHeader>Recruit</MenuHeader>
    </MenuHeaderLayout>

    <MenuList>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${SOCIAL_DISCOVERY_APP_ID}`}>
          <MenuIconWrapper>
            <MagnifyingGlassIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Creator Search</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink
          onClick={onItemSelected}
          to={'/member_table?filters={"segmentId":"source_shopify","value":[]}&sourcingId=shopify&switchSourcing=true'}
        >
          <MenuIconWrapper>
            <ShopifyIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Customers</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink
          onClick={onItemSelected}
          to={
            '/member_table?filters={"segmentId":"source_social listening","value":[]}&sourcingId=social listening&switchSourcing=true'
          }
        >
          <MenuIconWrapper>
            <AtIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Mentions</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>

      {/* <li className="flex items-center gap-4 overflow-hidden">
          <Avatar className="h-9 w-9 flex">
            <AvatarImage src="https://storage.googleapis.com/aspirex-static-files/new_logo.png" alt="Avatar" />
            <AvatarFallback>SD</AvatarFallback>
          </Avatar>
          <div className="grid gap-1 overflow-hidden truncate">
            <p className="text-sm font-medium leading-none m-0 truncate">Sofia Davis is a really long name</p>
          </div>
          <p className="text-sm text-muted-foreground group-hover:text-foreground m-0 ml-auto">39</p>
        </li> */}
    </MenuList>
  </MenuLayout>
);
type SecondaryNavMapType = {
  [K in SecondaryNavModes]: React.FC<SecondaryNavProps>;
};
const SecondaryNavMap: SecondaryNavMapType = {
  recruit: SecondaryRecruitNav,
  reporting: SecondaryReportingNav,
  projects: SecondaryProjectsNav,
  members: SecondaryMembersNav,
};
type SecondaryNavFactoryProps = {
  mode: SecondaryNavModes;
} & SecondaryNavProps;
export const SecondaryNavFactory = ({ mode, ...props }: SecondaryNavFactoryProps) => {
  const Component = SecondaryNavMap[mode];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

const pathsToMenuSelectionMap = {
  '/home': 'home',
  '/projects': 'projects',
  '/messages': 'messages',
  '/member_table': 'members',
};

export const MainLayout = ({ children }) => {
  const secondaryNavRef = useRef(null);
  const projectsRef = useRef(null);
  const membersRef = useRef(null);
  const recruitRef = useRef(null);
  const reportingRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = React.useState(false);
  const [secondaryNavMode, setSecondaryNavMode] = React.useState<SecondaryNavModes>();
  const secondaryNavPrevMode = useRef<SecondaryNavModes>();
  const projects = useProjects();
  const groups = useGroups();
  const { logout, user } = useAuth();
  const { profile } = useGetProfile();
  const { client: currentClient } = useGetCurrentClient();
  const pfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const installedApps = useGetInstalledApplicationIds();
  // set selected menu item based on the current route path
  const location = window.location.pathname;

  const selectedMenuItem = useMemo(() => {
    for (const path in pathsToMenuSelectionMap) {
      // if the current path matches the path in pathsToMenuSelectionMap
      if (location.includes(path)) {
        // set the selected menu item to the value of the path in pathsToMenuSelectionMap
        return pathsToMenuSelectionMap[path];
      }
    }
  }, [location]);

  useEffect(() => {
    if ((!isSidebarOpen || secondaryNavPrevMode.current !== secondaryNavMode) && secondaryNavRef.current) {
      secondaryNavRef.current.scrollTop = 0;
    }
    secondaryNavPrevMode.current = secondaryNavMode;
  }, [isSidebarOpen, secondaryNavMode]);

  const onSwitchClient = useCallback((client) => {
    const { location } = window;
    const rootDomain = location.hostname.split('.').slice(-2).join('.');

    if (rootDomain === 'localhost') {
      return location.assign(`/client/${client.id}`);
    }

    const hostname = client.hostname || 'community';

    return location.assign(`${location.protocol}//${hostname}.${rootDomain}/client/${client.id}`);
  }, []);

  const handleClickOutside = useCallback(() => {
    setIsSidebarOpen(false);
  }, [setIsSidebarOpen]);
  useOutsideAlerter({
    targetRef: secondaryNavRef,
    excludeRefs: [projectsRef, membersRef, recruitRef, reportingRef],
    callback: handleClickOutside,
  });

  const secondaryNavFactoryProps: SecondaryNavProps = {
    projects,
    onItemSelected: () => {
      setIsSidebarOpen(false);
    },
    groups,
  };

  return (
    <Layout>
      <Navbar>
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-start items-center">
            <Button
              size="icon"
              variant="ghost"
              onClick={() => {
                setIsMobileSidebarOpen(!isMobileSidebarOpen);
              }}
              className="sm:hidden mr-3"
            >
              <SidebarIcon className="h-5 w-5" />
              <span className="sr-only">Toggle Menu</span>
            </Button>
            <Link className="flex mr-4" to="/home">
              <img
                className="mr-3 h-8"
                alt="Aspire Logo"
                src="https://storage.googleapis.com/aspirex-static-files/new_logo.png"
              />
            </Link>
          </div>
          <div className="flex items-center lg:order-2 gap-2" />
        </div>
      </Navbar>
      <LeftSidebar isOpen={isMobileSidebarOpen}>
        <LeftSidebarContent>
          <div className="flex flex-col items-center justify-between sm:h-full h-[calc(100%-3.5rem)]">
            <ul className="flex flex-col items-center gap-4">
              <li>
                <Link className="flex" to="/home">
                  <img
                    className="h-8"
                    alt="Aspire Logo"
                    src="https://storage.googleapis.com/aspirex-static-files/new_logo.png"
                  />
                </Link>
              </li>
              <li className="">
                <Link
                  onClick={() => {
                    setIsMobileSidebarOpen(false);
                    setIsSidebarOpen(false);
                  }}
                  to="/home"
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'home' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'home'
                      && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                >
                  <HouseChimneyIcon className="h-4 w-4 " />
                  <ExtraSmall>Home</ExtraSmall>
                </Link>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={projectsRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'projects' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'projects'
                      && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onClick={() => {
                    setSecondaryNavMode('projects');
                    setIsSidebarOpen(secondaryNavMode === 'projects' ? !isSidebarOpen : true);
                  }}
                >
                  <SquareKanbanIcon className="h-4 w-4 " />
                  <ExtraSmall>Projects</ExtraSmall>
                </Button>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={membersRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'members' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'members'
                      && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onClick={() => {
                    setSecondaryNavMode('members');
                    setIsSidebarOpen(secondaryNavMode === 'members' ? !isSidebarOpen : true);
                  }}
                >
                  <UserGroupIcon className="h-4 w-4 " />
                  <ExtraSmall>Members</ExtraSmall>
                </Button>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={recruitRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'recruit' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'recruit'
                      && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onClick={() => {
                    setSecondaryNavMode('recruit');
                    setIsSidebarOpen(secondaryNavMode === 'recruit' ? !isSidebarOpen : true);
                  }}
                >
                  <UserPlusIcon className="h-4 w-4" />
                  <ExtraSmall>Recruit</ExtraSmall>
                </Button>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setIsMobileSidebarOpen(false);
                    setIsSidebarOpen(false);
                  }}
                  to="/messages"
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'messages' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'messages'
                      && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                >
                  <InboxIcon className="h-4 w-4" />
                  <ExtraSmall>Inbox</ExtraSmall>
                </Link>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={reportingRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'reporting' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'reporting'
                      && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onClick={() => {
                    setSecondaryNavMode('reporting');
                    setIsSidebarOpen(secondaryNavMode === 'reporting' ? !isSidebarOpen : true);
                  }}
                >
                  <ChartColumnIcon className="h-4 w-4 " />
                  <div>
                    <ExtraSmall>Reporting</ExtraSmall>
                  </div>
                </Button>
              </li>
            </ul>
            <div className="flex flex-col items-center gap-4 mt-auto">
              <MemberSearch />
              <UserMenu
                user={user}
                currentClient={currentClient}
                profile={profile}
                pfaV2Enabled={pfaV2Enabled}
                onSwitchClient={onSwitchClient}
                installedApps={installedApps}
                logout={logout}
              />
            </div>
          </div>
        </LeftSidebarContent>
        <SecondaryNav ref={secondaryNavRef} isOpen={isSidebarOpen}>
          <SecondaryNavFactory mode={secondaryNavMode} {...secondaryNavFactoryProps} />
        </SecondaryNav>
      </LeftSidebar>
      <MainContent>{children}</MainContent>
    </Layout>
  );
};
