import React, { useMemo } from 'react';
import { WidgetContainer, MainMetric } from '@frontend/app/composite-components';
import { Banknote } from 'lucide-react';
import { Bar, BarChart, XAxis } from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardBudgetTabSummaryQuery } from '../hooks';

const chartConfig = {
  project: {
    label: 'Allocated to Projects',
    color: 'hsl(var(--chart-1))',
  },
  account: {
    label: 'Allocated to Accounts',
    color: 'hsl(var(--chart-2))',
  },
} as ChartConfig;

interface TotalAllocatedWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function TotalAllocatedWidget(props: TotalAllocatedWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardBudgetTabSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
  });

  const chartData = useMemo(() => [
    { source: 'Allocated to Projects', value: budgetSummary?.summary?.allocatedBudget?.allocatedToProjects || 0, fill: 'var(--color-project)' },
    { source: 'Allocated to Accounts', value: budgetSummary?.summary?.allocatedBudget?.allocatedToAccounts || 0, fill: 'var(--color-account)' },
  ], [budgetSummary]);

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col">
      <MainMetric
        className="flex-1"
        value={budgetSummary?.summary?.allocatedBudget?.allocatedAmount || 0}
        size="medium"
        metricType="currency"
        subHeading="of Total Budget"
        icon={<Badge variant="outline" className="text-green-700">{`${budgetSummary?.summary?.allocatedBudget?.allocatedPercent || 0}%`}</Badge>}
      />
      <ChartContainer config={chartConfig} className="h-[150px] w-full">
        <BarChart data={chartData} margin={{ top: 0, left: 0 }}>
          <XAxis dataKey="source" tickLine={false} tickMargin={10} axisLine={false} />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Bar dataKey="value" strokeWidth={1} radius={4} barSize={35} />
          <ChartLegend content={<ChartLegendContent />} />
        </BarChart>
      </ChartContainer>
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Total Allocated"
      className={`${className}`}
      bgColor="bg-[#02609C]"
      widgetIcon={<Banknote className="text-white" size={16} />}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
