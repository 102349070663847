import React from 'react';
import { MainMetric, TrendIcon, WidgetContainer } from '@frontend/app/composite-components';
import { Pie, PieChart } from 'recharts';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';

interface AverageSpendOnCreatorWidgetProps {
  className?: string;
}

export default function AverageSpendOnCreatorWidget({ className }: AverageSpendOnCreatorWidgetProps) {
  const chartData = [
    { category: 'aboveAvg', percent: 35, fill: 'var(--color-aboveAvg)' },
    { category: 'belowAvg', percent: 65, fill: 'var(--color-belowAvg)' },
  ];

  const chartConfig = {
    percent: {
      label: 'Percent',
    },
    aboveAvg: {
      label: 'Above Average',
      color: 'hsl(var(--chart-1))',
    },
    belowAvg: {
      label: 'Below Average',
      color: 'hsl(var(--chart-2))',
    },
  } as ChartConfig;

  return (
    <WidgetContainer
      widgetTitle="Average Spend on Creators"
      className={`${className}`}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      <div className="flex flex-col">
        <MainMetric
          className="flex-1"
          value={1500001}
          size="medium"
          metricType="currency"
          subHeading="of Total Budget"
          icon={<TrendIcon trend="up" value="2%" />}
        />
        <div>
          <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[300px]">
            <PieChart>
              <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
              <Pie data={chartData} dataKey="percent" nameKey="category" innerRadius={40} />
              <ChartLegend
                content={<ChartLegendContent nameKey="category" />}
                className="flex-wrap gap-2"
              />
            </PieChart>
          </ChartContainer>
        </div>
      </div>
    </WidgetContainer>
  );
}
