export enum OFFER_STATUS_TYPE {
    ACTIVE = 'Active',
    DELETED = 'DELETED',
    FAILED = 'Failed',
    PAUSED = 'Paused',
    DEACTIVATING = 'Deactivating',
    DEACTIVATED = 'Deactivated',
    GENERATING = 'Generating',
    EXPIRED = 'Expired',
    UPCOMING = 'Upcoming',
    FAILED_UPDATE = 'Update Failed',
}

export enum CONVERSION_STATUS {
    ACCEPTED = 'Accepted',
    REJECTED = 'Rejected',
    CANCELED = 'Canceled',
}

enum TagType {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Processing = 'processing',
    Default = 'default',
}

const successTypes = [
    OFFER_STATUS_TYPE.ACTIVE, OFFER_STATUS_TYPE.ACTIVE.toLocaleUpperCase(),
    CONVERSION_STATUS.ACCEPTED, CONVERSION_STATUS.ACCEPTED.toLocaleUpperCase(),
];
const warningTypes = [
    OFFER_STATUS_TYPE.PAUSED, OFFER_STATUS_TYPE.PAUSED.toLocaleUpperCase(),
    CONVERSION_STATUS.CANCELED, CONVERSION_STATUS.CANCELED.toLocaleUpperCase(),
];
const errorTypes = [
    OFFER_STATUS_TYPE.FAILED, OFFER_STATUS_TYPE.EXPIRED,
    OFFER_STATUS_TYPE.FAILED.toLocaleUpperCase(), OFFER_STATUS_TYPE.EXPIRED.toLocaleUpperCase(),
    OFFER_STATUS_TYPE.DELETED, OFFER_STATUS_TYPE.DELETED.toLocaleUpperCase(),
    CONVERSION_STATUS.REJECTED, CONVERSION_STATUS.REJECTED.toLocaleUpperCase(),
    OFFER_STATUS_TYPE.DEACTIVATING, OFFER_STATUS_TYPE.DEACTIVATING.toLocaleUpperCase(),
    OFFER_STATUS_TYPE.DEACTIVATED, OFFER_STATUS_TYPE.DEACTIVATED.toLocaleUpperCase(),
    OFFER_STATUS_TYPE.FAILED_UPDATE, OFFER_STATUS_TYPE.FAILED_UPDATE.toLocaleUpperCase(),
];
const processingTypes = [
    OFFER_STATUS_TYPE.GENERATING, OFFER_STATUS_TYPE.GENERATING.toLocaleUpperCase(),
    OFFER_STATUS_TYPE.UPCOMING, OFFER_STATUS_TYPE.UPCOMING.toLocaleUpperCase(),
];

export const getTagType = (type: OFFER_STATUS_TYPE): TagType => {
    if (successTypes.includes(type)) {
        return TagType.Success;
    } else if (warningTypes.includes(type)) {
        return TagType.Warning;
    } else if (errorTypes.includes(type)) {
        return TagType.Error;
    } else if (processingTypes.includes(type)) {
        return TagType.Processing;
    } else {
        return TagType.Default;
    }
};
